import { Locales, supportedFileTypes } from '@acre/utils'

export const messagesClientPortal = {
  [Locales.GB]: {
    accessibility: {
      closeModal: 'Close modal',
    },
    address: {
      yourAddress: 'Your address',
      title: `What's your current address?`,
      dateTitle: 'When did you move into this address?',
      label: 'Search for a postcode',
      manual: 'Enter address manually',
      lookup: 'Look up address',
      line1: 'Address line 1',
      line2: 'Address line 2',
      line3: 'Address line 3',
      town: 'Town or city',
      postcode: 'Postcode',
      country: 'Country',
      addresses: 'Addresses',
      noResults: 'No results. Please enter your address manually.',
      placeholder: 'Search for a postcode',
    },
    alerts: {
      login: 'Your account has been logged out. To continue, please log in again.',
    },
    pageTitles: {
      dashboard: 'Dashboard',
      onboarding: {
        idd: 'Before we move on - Onboarding IDD',
        welcome: 'Welcome - Onboarding',
        welcomeScreen: 'Welcome - Personal Details',
        titleScreen: 'Title - Personal Details',
        nameScreen: 'Name - Personal Details',
        dobScreen: 'Date of Birth - Personal Details',
        addressScreen: 'Current Address - Personal Details',
        phoneScreen: 'Phone Number - Personal Details',
      },
      messages: 'Messages',
      home: 'Home',
      propertyData: 'Property Data',
      documents: 'Documents',
      properties: 'Properties',
      creditScore: 'Credit score',
      yourDetails: 'Your details',
      logout: 'Log out',
      settings: 'Settings',
    },
    settings: {
      dataPreferences: {
        header: 'Who should be able to see your data?',
        description: `This is a list of other people who have visibility of your information because you are on an application together. You can remove access for anyone who you don’t want to see your data.`,
        saveChanges: 'Save changes',
      },
      credit: {
        header: 'Do you want to get updated credit reports every 3 months?',
        description: 'This won’t affect your credit score and is free to use.',
        saveChanges: 'Save changes',
      },
      radio: {
        notAllowed: "Can't see any of my data",
        onlyCaseTogether: `Can see cases that we are on together`,
        allCases: 'Can see all of my cases',
      },
      navPanel: {
        header: 'Your settings',
        dataPreferences: 'Data preferences',
        creditSettings: 'Credit settings',
      },
      saveChangesModal: {
        header: `You haven't finished yet`,
        desc: `By pressing confirm, any changes you've made to limit or allow access to your data for others will be effective immediately.`,
        continue: 'Save Changes',
        cancel: 'Cancel',
      },
    },
    document: {
      yourDocuments: 'Your documents',
      viewAndManage: 'View and manage documents which your broker and yourself have uploaded',
      uploaded: 'Uploaded',
      allOwners: 'All Owners',
      allDocumentTypes: 'All document types',
      brokerDocuments: 'Broker documents',
      uploadedByYou: 'Uploaded by you',
      uploadedByBroker: 'Uploaded by your broker',
      uploadDocument: 'Upload document',
      downloadDocument: 'Download document',
      openInNewWindow: 'Open in new window',
      noDocuments: 'No documents',
      messageYourBroker: 'Message your Broker',
      signed: 'Accepted',
      actionNeeded: 'Action Needed',
      archived: 'Archived',
      loading: 'Loading...',
      suitabilityReport: {
        title: 'What is this document?',
        content: `<p>This is your Suitability Report, it is a document from {broker_name} outlining your current circumstances. It will also include anything you may have discussed with your broker about what you were hoping to achieve with your current application.</p>{br}<p>Please ensure you read it carefully and verify you are happy with its contents below.</p>{br}<p>If you have any questions speak to your broker as soon as possible.</p>`,
        signatureLabelSingle: `I {client}, verify that I have this document and accept the terms stated within it`,
        signatureLabelMultiple: `I {client}, verify that I have this document and accept the terms stated within it on behalf of myself{other_clients}`,
        signaturePlaceholder: `Type your name as seen above`,
        signatureDoesntMatchError: `Signature doesn't match`,
        conjunction: 'and',
        verifyDocument: 'Verify document',
      },
    },
    explainerModal: {
      previousStep: 'Previous',
      nextStep: 'Next',
      gettingToKnowYou: {
        header: `Getting to know you`,
        text: `<p>Welcome to the first stage of your mortgage application!</p>{br}
          <p>The aim at the moment is for your advisor to get a clear picture of you, your goals and your credit history.</p>{br}
          <p>This stage is made up of 4 parts:</p>{br}
          <ol>
            <li>Getting your Credit Report</li>
            <li>Passing an ID Verification step</li>
            <li>Telling us more about you</li>
            <li>Telling us what you are looking for with a mortgage</li>
          </ol>{br}`,
        expandTitle: 'Read less',
        closedTitle: 'Read more',
        expandText: `{br}{br}<ol>
          <li>An ID verification step. This is the legal bit to check you're who you say you are. You just need to take a selfie and upload a photo of your ID. This also helps with accessing your credit report.</li>{br}
          <li>Getting your credit report. Once you've added your details and proof of ID and give us your permission, we can access your credit report. 
          Your broker and lenders use this to assess how you've borrowed before. It helps them find you the right mortgage for your situation.</li>{br}
          <li>Information about you includes things like your address, employment, and any other applicants that might be on the mortgage application.</li>{br}
          <li>Information on what you're looking for with a mortgage means we ask about how much you're looking to borrow, if you'd want a fixed rate and how long you want your mortgage term to be.</li></ol>`,
        cta: `Get Started`,
      },
      review: {
        header: `Review and Recommendation`,
        text: `<p>You now have a mortgage recommendation! 🏠</p>{br}
          <p>There are important documents to review in your Documents dashboard.</p>{br}
          <p>You'll find your Suitability Report - this report explains the broker's thinking around why they think this mortgage will work for you.</p>{br}
          <p>Also, you have a European Standardised Information Sheet (ESIS) which goes into more detail about the mortgage conditions. 
          You'll be able to see details about any fees, an interest rate breakdown and monthly repayment illustration in the ESIS.</p>{br}`,
      },
      applicationSubmitted: {
        header: `Application submitted`,
        text: `<p>The lender is reviewing your application 📖</p>{br}
          <p>This stage can be slower than the others. The lender will take a look at your application and do a valuation of the property you're looking to buy. </p>{br}
          <p>Your broker will be in touch when this is done.</p>{br}<p>Now is a good time to think about other aspects of the process - 
          whether you need a conveyancer and who will be insuring your new property for you. Your broker will be able to help you arrange a conveyancer and home insurance
          if you haven't yet done so.</p>`,
      },
      awaitingValuation: {
        header: `Awaiting valuation`,
        text: `<p>The lender is reviewing your application 📖</p>{br}
          <p>This stage can be slower than the others. The lender will take a look at your application and do a valuation of the property you're looking to buy. </p>{br}
          <p>During a valuation a surveyor will look at:</p>{br}
          <ol>
            <li>The property's condition</li>
            <li>The area </li><li>Noise levels </li>
            <li>Where the sun rises and sets</li>
          </ol>{br}
          <p>They do this to see if the house price matches up with the mortgage amount. </p>{br}
          <p>Your broker will be in touch when this is done.</p>`,
      },
      offer: {
        header: `Offer received`,
        text: `<p>Congrats, your mortgage application has been accepted! 🎉</p>{br}
          <p>This means you've moved in to the conveyancing stage. </p>{br}
          <p>Conveyancing is the legal transfer of property ownership from the seller to you. 
          Basically, it's the paper work to get your name on the property deeds. A home purchase isn't legally binding until this has been done. </p>{br}`,
        expandTitle: 'How long will this take?',
        expandText: `{br}<p>It's hard to say exactly how long it'll take as it depends on how long the chain of sale is. </p>{br}
          <p>This stage involves your conveyancer working with the conveyancer of the owners of the property you're buying to do an 'exchange of contracts'. 
          Once your conveyancer or solicitor has drafted your contract with all the relevant Land Registry information and gotten your name on the deed,  
          you'll agree on a completion date. Then you can swap keys and move into your new home!</p>`,
      },
      exchange: {
        header: `Exchanged`,
        text: `<p>Congrats, you've exchanged contracts! 🎉</p>{br}
          <p>This means that you and the seller have made a legally binding commitment to proceed with the purchase and everything should be in place for legal completion.</p>{br}
          <p>Your broker will contact you and the buyer to confirm the 'exchange of keys' date.  There may be some back and forth on this. </p>{br}
          <p>Once the move-in date is sorted you can start thinking about redecorating!</p>`,
      },
      complete: {
        header: `Congrats, your home buying process is complete 🎉`,
        text: `<p>The legal process is now done, and you can start to think about redecorating!</p>{br}
            <p>It is important you have all the Protection you need for you and your new home - contact your broker as soon as possible, if you haven't already!</p>`,
      },
      protection: {
        header: `Welcome to your client portal!`,
        text: `The aim at the moment is for your advisor to get a clear picture of you, your goals and your Health and Protection needs.`,
      },
    },
    lockedModal: {
      title: 'This feature is currently locked!',
      content: `Your broker has not enabled this feature, please speak to them if you'd like to retrieve your credit report.`,
    },
    ory: {
      login: {
        header: 'Log into your client portal',
        subHeader: 'Enter your email address',
        label: 'Email address',
        button: 'Log in',
        bookmark: 'You can bookmark this page to make it quicker and easier to log in each time.',
        bannerHeader: 'We’ve made logging in to your portal more secure!',
        bannerDescription:
          'This page might look different to the last time you logged in, but don’t worry, this is just to make it easier and more secure to log in.',
        bannerButton: 'Switch to old log in',
      },
      createPassword: {
        header: 'Create a password',
        subHeader: 'This will make logging in easier in future.',
        label: 'Password',
        button: 'Continue',
        confirmLabel: 'Confirm Password',
        mixOfLetter: 'A mix of letters and numbers or symbols',
        upperLower: 'A mix of uppercase and lowercase letters',
        twelveChars: 'A minimum of 12 characters',
        passwordMismatch: 'Passwords must match',
      },
      verification: {
        header: 'Check your phone',
        subHeader: 'Enter the verification code we just sent to you.',
        label: 'Enter your verification code',
        button: 'Continue',
        resendLabel: 'Resend code',
        box: `<p>If you’re having trouble, make sure:</p><ul><li>You’re not using a VPN or private browsing</li><li>Cookies are enabled</li><li>You are using the latest code sent to your mobile phone</li></ul>`,
        invalidCode: 'Incorrect code, please try again',
        tooManyAttempts: {
          title: 'Too many log in attempts',
          description: 'For security reasons we need you to log in again and generate a new security code.',
          startAgain: 'Start again',
        },
      },
      emailVerification: {
        header: 'Check your emails',
        subHeader: 'We just sent you a verification code to make sure you have access to this email address.',
        label: 'Enter your verification code',
        button: 'Continue',
        resendLabel: 'Resend code',
        box: `<p>If you’re having trouble, make sure:</p><ul><li>You’re not using a VPN or private browsing</li><li>Cookies are enabled</li><li>You are using the latest code sent to your mailbox</li></ul>`,
        invalidCode: 'Incorrect code, please try again',
        tooManyAttempts: {
          title: 'Too many log in attempts',
          description: 'For security reasons we need you to log in again and generate a new security code.',
          startAgain: 'Start again',
        },
      },
      phoneNumbers: {
        header: `We need to verify it’s you`,
        description: `To keep your account secure, we need you to send a verification code to your phone to log in.{br}{br}We found a few mobile numbers for the email address you entered: {email}`,
        buttonText: `Send code to my phone`,
        box: `Can’t see your number? Check you entered your email address correctly on the previous page.`,
        buttonTextEmail: `Send code to my email address`,
        noPhoneNumber: 'Send code to my email address instead',
      },
      password: {
        header: 'Log into your client portal',
        subHeader: 'Now enter your password',
        label: 'Password',
        button: 'Continue',
        resetButton: 'Forgot Password',
        reachedRetryLimit: 'You have reached the maximum number of retries, please try again after an hour.',
        tryAgain:
          'It looks like something went wrong while recovering your password, please wait a while and try again.',
      },
      clientDisambiguation: {
        title: `We found a few clients using that email address…`,
        description: `Select which one of these you’d like to log in as:`,
        company: 'Advisor Company:',
        companyName: '{name}',
        advisor: 'Advisor Name:',
        advisorName: '{first} {last}',
        failedToSelectClient: `We're having trouble loading the account which you selected. This is probably temporary, so please try again in a few minutes.`,
      },
      caseDisambiguation: {
        title: `We found a few cases…`,
        date: 'Date created:',
        description: `Select which case you’d like to look at:`,
        company: 'Advisor Company:',
        companyName: '{name}',
        advisor: 'Advisor Name:',
        advisorName: '{first} {last}',
      },
      noInvite: {
        notExists: `We couldn’t find you!`,
        needsInvite: `You need to be invited by a mortgage broker to use this client portal.`,
        askBroker: `If you have a mortgage broker, ask them to invite you to your Client Portal`,
      },
      noCases: {
        header: 'No cases found',
        description: `We couldn’t find any cases for the email address you’ve provided.`,
        askBroker: `Get in touch with your broker to sort this out`,
      },
      flowExpired: {
        header: 'Log in session has expired',
        description: 'For security reasons this session has expired.',
        cta: 'Start again',
      },
    },

    login: {
      title: 'Generate your secure login link',
      cookieErrorTitle: 'Something went wrong',
      magicLingErrorTitle: "Oops, this link doesn't work!",
      noIddDescription: `We weren't able to log you in. If error reoccurs, please email your broker`,
      confirmationTitle: 'Check your inbox',
      desc: `To get started, we need to verify it's really you. In order to do that, we're going to send you a secure login link that you can use to access your portal (without a username or password).\n\n We'll send you an email with a new login link every time you access your account. This helps keep it super secure.\n\n Please make sure you have private browsing turned off, and always allow cookies to get the best experience when using your portal.`,
      cookieErrorDesc: 'Your broker has not enabled access to the client portal. Please contact them to gain access.',
      magicLingErrorDesc: 'Send yourself a new secure link to access your portal.',
      confirmationDesc: `We've sent a secure login link to {emailAddress}. If you didn't receive an email, click below to resend.`,
      emailNotRight: `Email address doesn't look right?`,
      clickToUpdate: 'Click here to update',
      label: 'Email address',
      placeholder: 'Confirm your email address',
      button: 'Send a secure link',
      resendEmail: 'Resend email',
      confirmationButton: 'Resend email',
      privateBrowsing: {
        title: `It looks like your cookies are disabled or you've cleared them since you last logged in!`,
        paragraph1: `If you're using a private browser:`,
        paragraph2: `If you're not using a private browsing:`,
        item1: 'Close the browser',
        item2: 'Reopen your invite email in a standard browser and make sure you have cookies enabled',
        item3: 'Enter your email below and try logging in again',
      },
      linkExpired: {
        heading: `Oops, this link doesn't work!`,
        paragraph1: 'This might be because the link was:',
        item1: 'Opened in a private browser',
        item2: 'In a browser with cleared cookies',
        item3: 'Opened using a different device',
        item4: 'Opened abroad',
        item5: 'Expired',
        paragraph2: "Let's get you back on track! Get a new log in link to continue with your application.",
        paragraph3:
          "<strong>You'll need to log in from the UK,</strong> if you're abroad please wait until you return before logging in.",
      },
      linkSent: {
        heading: 'Check your inbox',
        email: "We've sent your login link to {emailAddress}",
        paragraph1: "Don't forget, when you open the link make sure to:",
        item1: 'Open it in a standard browser, not private',
        item2: 'Have cookies enabled',
        item3: 'Log in using the same device',
        paragraph2: "You may need to check your spam email, if it's not there then you can resend the email",
        paragraph3: '<strong>This is a one time use link and expires in 15 minutes</strong>',
      },
      getLink: {
        paragraph1: 'For security reasons, we need to verify your email address.',
        paragraph2: "We'll send you a secure login link that you can use to access your portal.",
        paragraph3:
          "<strong>This is a one time use link and expires in 15 minutes</strong>. You'll need to log in from the UK, if you're abroad please wait until you return before logging in.",
        paragraph4: "When the link expires, you'll have to go back to your invite email to get another login link.",
      },
    },
    logout: 'Logout',
    profile: {
      aboutYou: 'About you',
      aboutTheOther: 'About {name}',
      panelHeader: `Here's what this section involves:`,
      panelList: `<li>Review or edit your personal information to ensure what we have is accurate</li><li>Make sure your broker has the most up to date contact information for you</li><li>Enter your current and past employment history</li>`,
      letsGetStarted: `Let's get started`,
      profileSection: 'Personal details',
      contactDetailsSection: 'Contact details',
      employmentSection: 'Employment details',
      idChecks: 'ID checks',
      creditReport: 'Credit report',
      tellUsAboutYourself: 'Tell us a bit about yourself...',
    },
    completion: {
      title: `You've finished this section!`,
      desc: 'Are you happy with everything you provided? You can now submit this to your broker for them to review and continue onto the next section of your fact find.',
      viewAnswers: 'View my answers',
      continueToNextStep: 'Go to dashboard',
    },
    saveChangesModal: {
      title: `You haven't completed all the information!`,
      desc: `You can do this now or come back later`,
      continue: 'Continue to next section',
      goBack: 'Go back',
      banner: `Your broker has not enabled this feature, please speak to them if you'd like to retrieve your credit report`,
    },
    dashboard: {
      button: 'Review Disclosure',
      heading: 'Hello, {name}!',
      whatsNext: `Review your Initial Disclosure Document`,
      goToProfile: 'Go to my profile',
      myProfile: 'Profile',
      mortgageApplication: 'Mortgage application',
      track: 'Track your progress',
      aboutYou: 'Tell us about you',
      aboutTheOther: 'Tell us about {name}',
      employmentAndAddressDetails: 'Have your employment and address details to hand',
      documents: 'Upload your documents',
      wills: 'Check whether your will is in order',
      willsDesc: 'Are your family, home and mortgage fully protected?',
      suitabilityReport: 'Review and Sign your Suitability Report',
      suitabilityReportDesc: 'Your broker has sent you a suitability report to read',
      protectionSuitabilityReport: 'Review and Sign your Health and Protection Suitability Report',
      protectionSuitabilityReportDesc: 'Your broker has sent you a Health and Protection suitability report to read',
      upload: 'Add your bank statements and proof of address',
      confirmDetails:
        'You need to review and accept your Initial Disclosure document before you can start using your portal',
      footer: 'Powered by',
      identityVerificationFallbackTitle: 'Verify your identity',
      identityVerificationFallbackDescription:
        'We need to verify your identity on behalf of your mortgage advisor. Please upload your identity documents.',
      identityVerificationFallbackCta: 'Complete ID verification',
      verifyId: 'Verify your identity',
      needAnId: `You'll need to take a photo of your ID and a selfie`,
      idVerificationStatus: 'ID Verification Status',
      checkIdStatus: 'Check the status of your ID verification',
      getYourCreditReport: 'Get your credit report',
      creditDesc: 'This will help find the right mortgage for your circumstances',
      creditAccess: 'Quick and free access to your credit report',
      yourGoals: 'Tell us your goals',
      tellBroker: 'Let your broker know what type of mortgage you want',
      yourBroker: '{firstName} {lastName} from {orgName}.',
      survey: 'Ensure you get the correct survey',
      surveyPurchase: 'Make sure you purchase the right survey for your property',
      conveyancing: 'Find a conveyancer',
      conveyancingPurchase: 'Get a dedicated expert conveyancer assigned to your case',
      fromOrganisation: 'from {orgName}.',
      message: 'Message',
      email: 'Email',
      bookMeeting: 'Book meeting',
      phone: 'Call',
      mobilePhone: 'Call Mobile',
      yourProperty: 'Your property',
      yourCreditScore: 'Your credit score',
      yourApplication: 'Your application',
      getMyCreditScore: 'Get my credit score',
      seeMyCreditScore: 'See my credit score',
      seeMyCreditReport: 'See my credit report',
      yourBrokerHasDisabledThis: 'Your broker has disabled this',
      verifyIdFirst: 'Please verify your ID first',
      setupPaymentMandate: 'Set up payments to your broker',
      paymentMandate: "You'll need to set up a mandate so your broker can charge you fees when your case completes",
      viewAll: 'View all',
      showCompletedTasks: 'Show completed tasks',
      hideCompletedTasks: 'Hide completed tasks',
      yourPropertyCard: {
        currentValue: 'Current value',
        mortgageBalance: 'Mortgage balance',
        purchasedFor: 'Purchased for {propertyPrice}',
        noProperty: 'No property to show yet',
      },
      yourApplicationCard: {
        requirementNotYetAnswered: 'Requirements not yet answered',
        loanAmount: 'Loan Amount',
        deposit: 'Deposit',
        requirements: 'Requirements',
        mortgageTypes: {
          REASON_FTB: 'Property purchase',
          REASON_HOUSE_MOVE: 'House move',
          REASON_REMORTGAGE: 'Remortgage',
          REASON_BTL: 'Buy-to-let',
          REASON_BTL_REMORTGAGE: 'Buy-to-let remortgage',
          REASON_EQUITY_RELEASE: 'Equity release',
          REASON_FURTHER_ADVANCE: 'Further advance',
          REASON_BUSINESS_PROTECTION: 'Business protection',
          REASON_PROTECTION: 'Protection',
          REASON_COMMERCIAL: 'Commercial mortgage',
          REASON_BRIDGING: 'Bridging finance',
          REASON_GENERAL_INSURANCE: 'Home insurance',
          unknown: 'Your application',
        },
        protectionRequirements: {
          lifeAndCriticalIllness: 'Life and Critical Illness',
          familyIncomeBenefits: 'Family Income Benefit',
          incomeProtection: 'Income Protection',
          wholeOfLife: 'Whole of Life',
          privateMedicalInsurance: 'Private Medical Insurance',
        },
      },
      homebuyersChecklistCard: {
        title: 'Are you mortgage ready?',
        desc: 'Use our home-buying checklist to answer any questions you have.',
        goToChecklist: 'Go to the checklist',
        viewProcess: 'See the whole process',
      },
      crossSaleCards: {
        conveyancing: {
          title: 'Need a Conveyancer?',
          desc: `Use the UK's leading independent conveyancing group.`,
        },
        wills: {
          title: 'Write your will',
          desc: 'Write your will in as little as 15 minutes, take care of what matters.',
        },
        survey: {
          title: 'Get a survey for your new property',
          desc: 'Check the condition of your new home with the right, RICS-approved survey for your property.',
        },
      },
      applicationSummary: {
        viewProcess: 'See the whole process',
        viewProcessChecklist: 'The home buying process',

        yourMortgageApplication: 'Your mortgage application',
        yourRemortgageApplication: 'Your remortgage application',
        yourProtectionApplication: 'Your Health and Protection application',
        yourInsuranceApplication: 'Your insurance application',
        borrowing: 'Borrowing',
        property: 'Property',
        status: 'Status',
        currentStep: 'Current step',
        mortgageType: '{caseType} type',
        mortgageTypes: {
          REASON_FTB: 'Property purchase',
          REASON_HOUSE_MOVE: 'House move',
          REASON_REMORTGAGE: 'Remortgage',
          REASON_BTL: 'Buy-to-let',
          REASON_BTL_REMORTGAGE: 'Buy-to-let remortgage',
          REASON_EQUITY_RELEASE: 'Equity release',
          REASON_FURTHER_ADVANCE: 'Further advance',
          REASON_BUSINESS_PROTECTION: 'Business protection',
          REASON_PROTECTION: 'Health and Protection',
          REASON_COMMERCIAL: 'Commercial mortgage',
          REASON_BRIDGING: 'Bridging finance',
          REASON_GENERAL_INSURANCE: 'Home insurance',
        },
        progressBanner: {
          gettingToKnowYou: 'Getting to know you',
          reviewAndRecommendation: 'Review and Recommendation',
          applicationSubmitted: 'Application submitted',
          valuationAndSurvey: 'Valuation and Survey',
          exchange: 'Exchanged',
          other: 'Other',
          offer: 'Offer',
        },
      },
      goCardlessIBP: 'Your broker has requested a payment of {price}. Click here to pay.',
    },
    requirements: {
      yourApplication: 'Your application',
      yourSituation: 'Your situation',
      aboutTheProperty: 'About the property',
      yourPreferences: 'Your preferences',
      conveyancer: 'Conveyancer',
      yourOtherProperties: 'Your other properties',
      panelHeader: `Here's what you'll need to tell us in your application:`,
      panelList: `<li>What you're looking to do</li><li>Price and address of the property</li><li>Your deposit sources</li><li>How you'd like to repay your mortgage</li><li>Details about any other properties you might own</li>`,
      estimatedDuration: 'This usually takes about 10 minutes.',
      committedExpenditure: {
        title: 'Committed expenditure',
        formTitle: 'Your monthly spending',
        formDesc: `The mortgage application process involves determining the borrowing capacity of an applicant. Lenders assess various factors, including an individual's expenses and income sources, such as employment and additional earnings. Monthly expenditures are usually categorised into two groups to help lenders decide whether to approve a mortgage.`,
        accordionTitle: 'Committed expenditure and discretionary expenditure',
        accordionDesc: `Committed Expenditure includes items like utilities, food, council tax, travel costs and insurances. The way to think about committed expenditure is anything you know you need to spend each month to keep your house and life running, or contracts you have committed to paying for a period of time.{br}{br}Discretionary expenditure would include non-essential items like you clothes shopping, going to the cinema or that coffee you enjoy buying on the weekend!`,
        yourMonthlyCommittedExpenditure: 'Your monthly committed expenditure',
        entryWarning:
          'If you share any of these outgoings with the other applicant, please enter the total for you both. Upon saving, the total you enter will be split between you and the other applicant(s).',
        water: 'How much do you spend on water?',
        fuel: 'How much do you spend on electricity and heating?',
        councilTax: 'How much do you spend on council tax?',
        communications: 'How much do you spend on phone and internet?',
        otherTransport: 'How much do you spend on other transport costs?',
        tvLicense: 'How much do you spend on your television license?',
        carCosts: 'How much do you spend on car costs?',
        maintenance: 'How much do you spend on spousal / child maintenance?',
        tvLicenseYes: 'I pay for a TV license',
        tvLicenseNo: `I don't need a TV license`,
        carYes: 'I own a car or motorbike',
        carNo: `I don't own a car or motorbike`,
        maintenanceYes: 'I pay spousal/child maintenance',
        maintenanceNo: `I don't pay spousal/child maintenance`,
      },
      discretionaryExpenditure: {
        title: 'Discretionary expenditure',
        formTitle: 'Your average monthly discretionary expenditure',
        accordionTitle: 'How accurate do these figures need to be?',
        accordionDesc:
          'It is essential to provide an average monthly amount spent in each expenditure category. While complete accuracy is not required, reviewing a three month period and calculating the average is a recommended approach to obtain a reliable estimate.',
        food: 'How much do you spend on food?',
        clothing: 'How much do you spend on clothing?',
        entertainment: 'How much do you spend on entertainment?',
        holidays: 'How much do you spend on holidays?',
        childCare: 'How much do you spend on child care?',
        childCareYes: 'I have 1 or more children',
        childCareNo: `I don't have children`,
        householdMaintenance: 'How much do you spend on household maintenance?',
        privatePension: 'How much do you spend on private pension contributions?',
        privatePensionYes: 'I contribute to a private pension',
        privatePensionNo: `I don't contribute to a private pension`,
        investments: 'How much do you spend on investments?',
        investmentsYes: 'I invest my money',
        investmentsNo: `I don't invest my money`,
        insurance: 'How much do you spend on insurances?',
      },
    },
    creditDetails: {
      tooltip:
        'Your credit score may look different to what you have seen before, this is due to the score being out of 700. Other credit agencies sometimes score up to 1000. Please ensure you read your full report to check all your information is correct',
      yourCreditScore: 'Your credit score',
      retrievalDate: `Powered by Equifax - Retrieved on {retrievalDate}`,
      creditDetailsHeader: {
        personalInformation: 'Personal information',
        name: 'Name',
        dateOfBirth: 'Date of birth',
        address: 'Address',
        onRoll: `You're on the electoral roll for this address`,
        offRoll: `You're not on the electoral roll for this address`,
      },
      creditReportNotReceived: {
        title: `Oh no, we're sorry!`,
        desc: `We haven't yet received your credit report, please get in touch with your broker and they will assist you`,
      },
      noCreditReport: {
        title: `Oh no, we're sorry!`,
        desc: `Unfortunately we couldn't find your credit file. Please get in touch with your broker and they will assist you.`,
      },
      scores: {
        excellent: 'Excellent',
        good: 'Good',
        average: 'Average',
        poor: 'Poor',
        veryPoor: 'Very Poor',
        noScore: 'This will help speed up your application',
        scoreDescription: 'out of 700',
      },
      repaymentHistory: {
        title: 'Repayment history',
        onTime: 'On time',
        paymentMissed: 'Payment missed',
        noData: 'No data',
      },
      insights: {
        hideDetails: 'Hide details',
        showDetails: 'Show details',
        legalAction: {
          type: 'Type',
          bankruptcy: 'Bankruptcy',
          ccj: 'CCJ',
          iva: 'IVA',
          noLegalActionTitle: 'No recent legal action',
          noLegalActionDesc: `You don't have any bankruptcies, CCJs or IVAs on your report`,
          legalActionPresent: 'Legal action present',
          legalActionPresentDesc: 'You have a bankruptcy, CCJ or IVA on your report',
          bankruptcyDate: 'Bankruptcy date',
          courtName: 'Court name',
          dischargeDate: 'Discharge date',
          dateRegistered: 'Date registered',
          dateSatisfied: 'Date satisfied',
          dateCompleted: 'Date completed',
          amount: 'Amount',
        },
        creditUtilization: {
          title: 'Overall credit utilisation',
          description: '{utilisation}',
        },
        electoralRoll: {
          notRegistered: 'You are not on the electoral roll',
          notRegisteredDesc: 'Register to improve your score',
          registered: 'You are on the electoral roll',
          homeAddress: 'At your home address',
          differentAddress: 'At a different address',
        },
        arrears: {
          noArrearsTitle: 'No accounts in arrears',
          noArrearsDesc: `You don't have any accounts with unsettled debt`,
          arrearsTitle: 'Accounts in arrears',
          arrearsDesc: 'You have an account with unsettled debt',
          creditorName: 'Creditor name',
          dateOfLastArrears: 'Date of last arrears',
        },
      },
      previousSearches: {
        title: 'Previous searches',
        search: 'Search',
        searcher: 'Searcher',
        searches: 'Searches',
        date: 'Date latest search',
        new: 'New',
      },
      accountsTable: {
        title: 'Your accounts',
        creditType: 'Credit type',
        creditor: 'Creditor',
        balance: 'Balance',
        utilisation: 'Utilisation',
        status: 'Status',
        collapseAccounts: 'Collapse accounts',
        showAccounts: 'Show all accounts',
        notApplicable: 'N/A',
        statuses: {
          ACCOUNT_STATUS_SETTLED: 'Settled',
          ACCOUNT_STATUS_OPEN: 'Active',
          INVALID_ACCOUNT_STATUS: 'Invalid status',
        },
        monthlyRepayment: 'Monthly repayment',
        accountNumber: 'Account number',
        startDate: 'Start date',
        remainingTerm: 'Remaining term',
        remainingTermMonths: '{months} months',
        raiseADispute: 'Raise a dispute',
        disputeModal: {
          title: 'You are able to file a dispute with Acre if:',
          desc1: 'You see information on your Acre credit report that you believe is inaccurate or incomplete.',
          desc2: 'You notice information on your Acre credit report that you believe is the result of fraud.',
          howItWorks: 'How it works',
          point1A: `File a dispute for free - If you see information on your Acre credit report that you believe is inaccurate or incomplete, contact us by emailing `,
          point1B: ` and we'll raise it with Equifax on your behalf.`,
          point2: 'Equifax will investigate and respond within 30 days.',
          point3: `Equifax will inform Acre of any outcomes from their investigation, including any relevant corrections to your credit report.`,
        },
      },
      debtTypes: {
        INVALID_DEBT_TYPE: 'Invalid debt type',
        INVALID_COMMITMENT_TYPE: 'Invalid commitment type',
        CREDIT_CARD: 'Credit card',
        OVERDRAFT: 'Overdraft',
        STORE_CARD: 'Store card',
        LOAN: 'Loan',
        HIRED_PURCHASE: 'Hired purchase',
        SECURED_EXCHANGE: 'Secured exchange',
        UTILITIES: 'Utilities',
        COMMUNICATIONS: 'Communications',
        MAIL_ORDER: 'Mail order',
        PAY_DAY: 'Pay day',
        MORTGAGE: 'Mortgage',
      },
    },

    credit: {
      overview: 'Overview',
      getYourCreditReport: 'Get your credit report',
      tryAgain: 'Try again',
      uploadYourId: 'Upload proof of ID',
      creditTermsAndConditions: `Credit report T&Cs`,
      idChecks: 'ID checks',
      creditReport: 'Credit report',
      documentAccepted: 'Document accepted',
      getMyCreditReport: 'Get my credit report',
      viewMyCreditReport: 'View my credit report',
      returnToDashboard: 'Return to dashboard',
      unexpectedError: 'Unexpected error - please contact your broker.',
      creditOverview: {
        yourCreditReport: 'Your credit report',
        yourCreditReportDesc:
          'Reviewing your credit history and credit score can help you better understand your current credit position. Regularly checking your credit reports can also help you detect any inaccurate or incomplete information.',
        getYourCreditReport: 'Get your credit report',
        getYourCreditReportDesc: `To help your advisor understand your credit history, you need to get a copy of your credit report. <strong>This won't affect your credit score!</strong>`,
        creditScoreWarning: `This won't affect your credit score.`,
        accordionTitle: 'Why do we need this?',
        accordionDesc: `<p>Lenders will look at your credit report to see:</p>{br}<ul><li>Your credit history (any credit cards or loans you've got or had)</li><li>How much you currently owe</li><li>If you've missed any payments</li><li>If you're registered to vote</li></ul>{br}<p>They mainly do this so they can see you're reliable with repayments, and are more likely to lend to you.</p>{br}<p>With an up to date credit report available for your broker to review, they can make sure they find the right lender for you.</p>`,
        getReportOptions: 'How would you like to get your credit report?',
        doThisLater: `I'll do this later`,
        useThisOption: 'Use this option',
        confirmYourId: 'Using a form of ID',
        confirmYourIdDesc: 'Take a photo of your ID and then take a selfie to check they match.',
        securityQuestions: 'Security questions',
        securityQuestionsDesc: 'Answer security questions based on information from your credit report.',
      },

      kba: {
        question: 'Question {current}/{max}',
        nextQuestion: 'Next question',
        result: {
          pass: {
            title: `You've successfully verified your identity!`,
            content: `You can now see your credit report and we've also sent a copy to your mortgage broker.`,
          },
          fail: {
            title: 'Authentication failed',
            content: `<p>Sorry, we can't get your credit report. The answers given don't match the information on the credit file.</p>{br}<p>You can try again or retrieve your credit report by uploading proof of ID.</p>`,
          },
          clientNotFound: {
            title: 'Authentication failed',
            content: `<p>We haven't been able to run a credit search as we can't find you based off the information we have so far. Please check your information before retrying.</p>`,
          },
          error: {
            title: 'Authentication failed',
            content: `<p>It looks like there was a technical issue causing the authentication to fail. This doesn't mean that the answers were incorrect so please try again.</p>`,
          },
          maxAttempts: {
            title: `You've exceeded the max number of attempts`,
            content: `<p>Sorry, we can't get your credit report via knowledge-based authentication.</p>{br}<p>To get your credit report now, you'll have to upload proof of ID.</p>`,
          },
          failedToRetrieveCreditReport: {
            title: 'Thank you for verifying your identity',
            content: `We are currently processing your request, but we encountered a small issue while generating your credit report. For further assistance, please email support at <a>support@myac.re</a>.`,
          },
        },
        error: 'An error has occurred or your questionnaire has timed out, please start again.',
      },
      identity: {
        requiredToConfirm: `To protect you and to check that this is the real you, we're required to confirm your identity.`,
        authenticatingTitle: 'Getting your results',
        authenticatingDesc: 'Sit tight, this usually takes around 40 seconds',
        welcomeScreen: 'We need to verify your identity on behalf of your mortgage advisor',
        welcomeScreenDescription: `It should only take a minute or two. You'll need a piece of photo ID to hand.`,
        cta: `Let's do this`,
        shareDocsWithAdvisor: `We've shared your documents with your mortgage advisor`,
        idVerified: 'ID verified',
        idNotVerified: 'ID not verified',
        messageMyBroker: 'Message my Broker',
        useKnowledgeBasedAuth: 'Use knowledge-based authentication',
        exceededAttemptsTitle: `You've exceeded the max number of attempts`,
        exceededAttemptsDesc: `<p>Sorry, we can't get your credit report using proof of ID.</p>{br}<p>To get your credit report now, you'll have to confirm your identity via knowledge-based authentication.</p>`,
      },
      iddBanner: 'By accepting and continuing, you are confirming that you agree to the terms of service and the ',
      portal: ' (which is available in the portal).',
      reacceptCreditIdd: `*You previously accepted the terms, but didn't complete an ID check. We need you to accept the terms again before retrieving your credit report.`,
      acrePP: `Acre Support UK's Privacy Policy`,
      lockedAddressModal: {
        title: 'Please add your full address',
        desc: 'In order to continue, you need to add your full address. If you believe this is an issue, please contact Acre support.',
        addFullAddress: 'Add my full address',
      },
      identitySuccess: {
        success: `You've successfully verified your identity!`,
        notifyBroker: `We will notify your broker to make sure that they're aware you've verified your identity.`,
        getCreditReport: 'You can now get your free credit report',
        getCreditReportDesc:
          "To help your advisor understand your credit history, you need to get a copy of your credit report. <strong>This won't affect your credit score!</strong>",
      },
    },

    eidv: {
      olderThanThreeMonths: {
        header: 'The electronic ID verification we hold on file has expired.',
        content: `<p>We need updated records every three months - please continue below to verify your ID again.</p>`,
        cta: 'Try again',
      },
      failedSelfie: {
        header: `We weren't able to verify your selfie`,
        content: `<p><strong>Your selfie wasn't clear enough</strong>, so you need to retake it to complete your ID verification.</p><p>When you take your selfie, please make sure:</p><ul><li>You're fully facing the camera</li><li>Your glasses are off and your eyes are clearly visible</li><li>It's not dark </li><li>The photo doesn't have any glare - you might have to turn your flash off</li><li>It's not blurry</li><li>You're taking a photo of yourself and not a scan or image of a photo of yourself</li></ul>`,
        cta: 'Try again',
      },
      failedID: {
        header: `We weren't able to verify your ID`,
        content: ` <p><strong>The photo of your ID wasn't clear enough or you might've uploaded a scanned image</strong>, so you need to retake it to complete your ID verification.</p><p>When you take a photo of your ID, please make sure:</p><ul><li>You don't cover any of the corners</li><li>You can clearly see your face and the text on the ID card</li><li>There's no shine or reflections, try turning your flash off</li><li>The photo isn't blurry</li><li>You take a photo, scanned images will not be accepted</li></ul>`,
        cta: 'Try again',
      },
      failedIdOrSelfie: {
        header: `We weren't able to verify your ID and selfie`,
        content: `<p><strong>The photo of your ID and your selfie weren't clear enough, or you might've uploaded a scanned image</strong>, so you need to redo both to complete your ID verification.</p><p>When you take your photos, please make sure:</p><ul><li>You don't cover any of the corners of your ID</li><li>You can clearly see your face and the text on the ID card</li><li>There's no shine or reflections, try turning your flash off</li><li>The photo isn't blurry or too dark</li><li>You're fully facing the camera</li><li>Your glasses are off and your eyes are clearly visible</li><li>You take a photo, scanned images will not be accepted</li></ul>`,
        cta: 'Try again',
      },
      manualReview: {
        header: `We need to manually review your documents`,
        content: `<p>This hasn't worked the way we'd hoped, so we'll have someone manually review your documents.</p><p>We'll send you an email once we've checked, <strong>this should take about 24 hours.</strong></p>`,
      },
      beingProcessed: {
        header: `Oh no, this is taking longer than we anticipated!`,
        content: `<p>It is taking us a bit longer than usual to verify your identity. Please return to your dashboard to complete information your broker needs or upload your documents.</p><p>When we have a result you will be notified by email.</p>`,
        cta: 'Try knowledge-based authentication',
      },
      passed: {
        header: `You've successfully verified your identity!`,
        content: `You can now see your credit report and we've also sent a copy to your mortgage broker.`,
        cta: 'View my credit report',
      },
      failedToRetrieveCreditReport: {
        header: 'Thank you for verifying your identity',
        content: `We are currently processing your request, but we encountered a small issue while generating your credit report. For further assistance, please email support at <a>support@myac.re</a>.`,
      },
      rejected: {
        tryAgain: 'Try again',
        messageMyBroker: 'Message My Broker',
        editMyInformation: 'Edit my information',
        comparison: {
          general: {
            header: `Some of the information you provided doesn't match your document`,
            content: `<p>Whilst matching the data on the document you uploaded against the information you've provided us, some of them didn't quite match.</p><p>Please review and edit your information. When you return you can try and verify your identity again.</p>`,
          },
          firstname: {
            header: `Some of the information you provided doesn't match your document`,
            content: `<p>The first name on the document you uploaded did not match the information you provided us.</p><p>Please review and edit your information. You can try again when you return.</p>`,
          },
          surname: {
            header: `Some of the information you provided doesn't match your document`,
            content: `<p>The last name on the document you uploaded did not match the information you provided us.</p><p>Please review and edit your information. You can try again when you return.</p>`,
          },
          date_of_birth: {
            header: `Some of the information you provided doesn't match your document`,
            content: `<p>The date of birth on the document you uploaded did not match the information you provided us.</p><p>Please review and edit your information. You can try again when you return.</p>`,
          },
        },
        validation: {
          genderDob: {
            header: 'We cannot verify your document at the moment',
            content: `<p>Don't worry though, there's multiple reasons which could cause this. You can try again, but make sure the following criteria are met:</p><ul><li>The document uploaded is the original, photocopied documents will not pass</li><li>Your face is clearly displayed on the document being uploaded</li></ul>`,
          },
          date: {
            header: 'We cannot verify your document at the moment',
            content: `<p>Don't worry though, there's multiple reasons which could cause this. You can try again, but make sure the following criteria are met:</p><ul><li>The document uploaded is the original, photocopied documents will not pass</li><li>Your face is clearly displayed on the document being uploaded</li></ul>`,
          },
          numbers: {
            header: 'We cannot verify your document at the moment',
            content: `<p>Don't worry though, there's multiple reasons which could cause this. You can try again, but make sure the following criteria are met:</p><ul><li>The document uploaded is the original, photocopied documents will not pass</li><li>Your face is clearly displayed on the document being uploaded</li></ul>`,
          },
        },
        imageIntegrity: {
          imageIntegrity: {
            header: 'Oops, the image uploaded did not meet our requirements',
            content: `<p>The image which you uploaded did not meet the requirements needed for us to be able to verify your identity. Please ensure the following:</p><ul><li>The image is clear and not blurry</li><li>The image uses one of the required file types</li><li>You're using a colour picture</li><li>You're uploading a photo of the document, not a scanned image</li></ul>`,
          },
          imageIntegrityBW: {
            header: 'Oops, the image uploaded did not meet our requirements',
            content: `<p>You uploaded a black and white image, please upload a colour photo.</p><ul><li>The image uses one of the required file types</li><li>You're using a colour picture</li><li>You're uploading a photo of the document, not a scanned image</li></ul>`,
          },
          supportedDocument: {
            header: 'We cannot verify your document at the moment',
            content: `<p>Don't worry though, there's multiple reasons which could cause this. You can try again, but make sure the following criteria are met:</p><ul><li>The document uploaded is the original, photocopied documents will not pass</li><li>Your face is clearly displayed on the document being uploaded</li></ul>`,
          },
        },
        visualAuthenticity: {
          visualAuthenticity: {
            header: 'Oops, the image uploaded did not meet our requirements',
            content: `<p>The image which you uploaded did not meet the requirements needed for us to be able to verify your identity. Please ensure the following:</p><ul><li>The image is clear and not blurry</li><li>The image uses one of the required file types</li><li>You're using a colour picture</li><li>You're uploading a photo of the document, not a scanned image</li></ul>`,
          },
          security: {
            header: 'We cannot verify your document at the moment',
            content: `<p>Don't worry though, there's multiple reasons which could cause this. You can try again, but make sure the following criteria are met:</p><ul><li>The document uploaded is the original, photocopied documents will not pass</li><li>Your face is clearly displayed on the document being uploaded</li></ul>`,
          },
          originalDocument: {
            header: 'We think you may be using a scanned image or a photo of a photo',
            content: `<p>The image which you uploaded did not meet the requirements needed for us to be able to verify your identity. Please ensure the following:</p><ul><li>You're uploading a photo of the document, not a scanned image or a photo of a photo.</li></ul>`,
          },
        },
        consistency: {
          expiryDate: {
            header: `Sorry, there's an issue with some of the data on the uploaded document`,
            content: `<p>The most common cause for this is the uploaded document has expired. Please make sure you're uploading a valid document so that we can verify your identity.</p>`,
          },
          issuingCountry: {
            header: 'We do not support the document you have uploaded',
            content: `<p>Sorry, we can't verify your identity as we do not accept the document you have uploaded. You can try again, but please check:</p><ul><li>We accept the form of ID you are trying to use</li><li>We accept ID's from the country your ID is issued from</li></ul>`,
          },
          numbers: {
            header: 'We cannot verify your document at the moment',
            content: `<p>Don't worry though, there's multiple reasons which could cause this. You can try again, but make sure the following criteria are met:</p><ul><li>The document uploaded is the original, photocopied documents will not pass</li><li>Your face is clearly displayed on the document being uploaded</li></ul>`,
          },
          personalData: {
            header: `Some of the information you provided doesn't match your document`,
            content: `<p>Whilst matching the data on the document you uploaded against the information you've provided us, some of them didn't quite match.</p><p>Please review and edit your information. When you return you can try and verify your identity again.</p>`,
          },
          multipleDocuments: {
            header: 'Oops, the image uploaded did not meet our requirements',
            content: `<p>The image which you uploaded did not meet the requirements needed for us to be able to verify your identity. Please ensure the following:</p><ul><li>The image is clear and not blurry</li><li>The image uses one of the required file types</li><li>You're using a colour picture</li><li>You're uploading a photo of the document, not a scanned image</li></ul>`,
          },
          names: {
            header: `Some of the information you provided doesn't match your document`,
            content: `<p>Whilst matching the data on the document you uploaded against the information you've provided us, some of them didn't quite match.</p><p>Have you changed your last name recently or are you using a nickname?</p>`,
          },
        },
      },
    },
    propertyList: {
      propertyType: 'Property type',
      numberOfBedrooms: 'No. of bedrooms',
      propertyUse: 'Property use',
      tenure: 'Tenure',
      originalPrice: 'Original price',
      currentValuation: 'Current valuation',
      portfolio: 'Portfolio',
      noPropertiesFound: 'No properties found',
    },
    propertyData: {
      header: 'Property data and information',
      subHeader:
        'Here you can find essential information about your property including energy ratings, broadband speeds, and local property prices.',
      noData: 'No property data found',
      noDataWithIdentifier: 'No {value} data found',
      noDataTitle: `Oh no, we're sorry!`,
      noDataDesc: `<p>Unfortunately, we've been unable to identify property information for this case. This can happen for several reasons:</p><li>New build properties do not always yet show in property data. Please check back nearer your move in date.</li><li>If you've manually entered an address rather than using the "Lookup" function, we can't always identify the property.</li><li>We're unable to show data for non-residential properties.</li><li>Less information is available for properties in Scotland, and we're currently unable to source detailed data for properties in Northern Ireland.</li>`,
      address: 'Address',
      title: 'Title',
      titleDescription: `This property holds a {value} title`,
      price: 'Price',
      ceremonialCounty: 'Ceremonial county',
      county: 'County',
      dropDown: 'What is the property title?',
      dropdownContent: `<p>When you are buying a house, you will have heard of freehold and leasehold titles. While these terms may sound complicated, they have two simple meanings:</p>{br}<ul><li><b>Freehold</b> – You own the property and the land it's built on for as long as you want.</li><li><b>Leasehold</b> – You own the property for a set period, but not the land it's built on.</li></ul>{br}<p>With a freehold, you own the house and the land it's built on. You do not need to worry about leases, you won't have to pay ground rent or any maintenance fees. Freehold is the most common way to buy a house in the UK.</p>{br}<p>With a leasehold is where you buy the property, but not the land it is built on. The land is still owned by the freeholder, who is selling the property for a set period of time. It's more common with flats, but there are leasehold houses too.</p>{br}<p>Leaseholds normally last between 125 and 999 years. If you buy a leasehold property, you should be able to extend how long you own it for. Shared spaces, like gardens and hallways, are usually the responsibility of the landlord.</p>`,
      yourProperty: 'Your Property',
      similarProperties: `Similar properties in {post_code}`,
      thisProperty: 'This property',
      propertyPrices: {
        header: `Property prices in {post_code}`,
        headerNoData: 'Property prices',
      },
      communications: {
        header: 'Broadband',
        subHeader: 'Here is expected availability of broadband internet services and mobile signal at your address',
        adsl: 'ADSL',
        superFast: 'Super-fast',
        ultraFast: 'Ultra-fast',
        download: 'Download',
        upload: 'Upload',
        speed: 'Mbps',
        footer:
          'Figures provided by Ofcom, the communications regulator, provides information about expected availability of broadband services at every address in the UK. This information is for advisory purposes only and may depend on factors not available to the regulator.',
      },
      epc: {
        header: 'Energy efficiency rating',
        subheader: `Almost 40% of the UK's energy consumption come from the way our buildings are heated and used.`,
        graphHeader: 'very energy efficient - lower running costs',
        graphFooter: 'not energy efficient - higher running costs',
        explainerBoxHeader: 'What is an EPC rating?',
        explainerBox: `<p>An EPC rating stands for a Energy Performance Certificate. This is a legal document which provides an energy efficiency rating  for your property and how much it's running costs are on average. The energy efficiency rating will be displayed on an A to G scale. The least efficient buildings will be rated G and the most efficient will be rated A.</p>{br}<p>This rating will take into account the potential energy performance of the property itself (the fabric) and your utilities such as heating your home.</p>`,
        energyEfficient: 'very energy efficient - lower running costs',
        notEnergyEfficient: 'not energy efficient - higher running costs',
        noEPCTitle: `Oh no, we're sorry!`,
        noEPCDesc: `<p>Unfortunately, we've been unable to retrieve the EPC certificate for this property. This can happen for several reasons:</p><li>EPC data for new build properties may not always be available immediately. Please check back nearer your move in date.</li><li>If you've manually entered an address rather than using the "Lookup" function, we can't always identify the property.</li><li>We're unable to show data for non-residential properties.</li><li>Less information is available for properties in Scotland, and we're currently unable to source detailed data for properties in Northern Ireland.</li>`,
        viewFullEPC: 'View full EPC',
        energyPerformanceCertificate: 'Energy Performance Certificate',
        recommendations: 'Recommendations',
        recommendationsDesc: 'These are things you can do to improve the energy efficiency of this property',
        improvement: 'Improvement',
        costEstimate: 'Cost estimate',
        getAQuote: 'Get a quote',
        poweredBy: 'Powered by',
        ratedExcellent: 'Rated excellent',
        ratedPeopleReviews: 'Over 17,500 reviews • Excellent',
        certificateDetails: 'Certificate details',
        co2Emissions: 'CO2 emissions',
        energyConsumption: 'Energy consumption',
        flatData: 'Flat data',
        flooring: 'Flooring',
        windowsAndGlazing: 'Windows and glazing',
        heating: 'Heating',
        lighting: 'Lighting',
        roof: 'Roof',
        walls: 'Walls',
        address: 'Address',
        property_type: 'Property type',
        built_form: 'Built form',
        tenure: 'Tenure',
        construction_age_band: 'Construction age band',
        inspection_date: 'Inspection date',
        lodgement_date: 'Lodgement date',
        current_energy_rating: 'Current energy rating',
        potential_energy_rating: 'Potential energy rating',
        current_energy_efficiency: 'Current energy efficiency',
        potential_energy_efficiency: 'Potential energy efficiency',
        total_floor_area: 'Total floor area',
        co2_emissions_current: 'CO₂ emissions current',
        co2_emissions_potential: 'CO₂ emissions potential',
        co2_emiss_curr_per_floor_area: 'CO₂ emiss curr per floor area',
        energy_tariff: 'Energy tariff',
        energy_consumption_current: 'Energy consumption current',
        energy_consumption_potential: 'Energy consumption potential',
        wind_turbine_count: 'Wind turbines',
        photo_supply: 'Photo supply',
        mains_gas_flag: 'Mains gas',
        extension_count: 'Extensions',
        floor_description: 'Floor description',
        floor_energy_eff: 'Floor energy efficiency',
        floor_env_eff: 'Floor environmental efficiency',
        floor_level: 'Floor level',
        glazed_area: 'Glazed area',
        glazed_type: 'Glazing type',
        windows_description: 'Windows description',
        windows_energy_eff: 'Windows energy efficiency',
        windows_env_eff: 'Windows environmental efficiency',
        multi_glaze_proportion: 'Multi glaze proportion',
        number_habitable_rooms: 'Number of habitable rooms',
        number_heated_rooms: 'Number of heated rooms',
        number_open_fireplaces: 'Number of open fireplaces',
        main_fuel: 'Main fuel',
        main_heating_controls: 'Main heating controls',
        mainheatcont_description: 'Main heating control description',
        mainheatc_energy_eff: 'Main heating control energy efficiency',
        mainheatc_env_eff: 'Main heating control environmental efficiency',
        mainheat_description: 'Main heating description',
        mainheat_energy_eff: 'Main heating energy efficiency',
        mainheat_env_eff: 'Main heating environmental efficiency',
        secondheat_description: 'Secondary heating description',
        sheating_energy_eff: 'Secondary heating energy efficiency',
        sheating_env_eff: 'Secondary heating environmental efficiency',
        mechanical_ventilation: 'Mechanical ventilation',
        lighting_cost_current: 'Lighting cost current',
        lighting_cost_potential: 'Lighting cost potential',
        lighting_description: 'Lighting description',
        lighting_energy_eff: 'Lighting energy efficiency',
        lighting_env_eff: 'Lighting environmental efficiency',
        low_energy_fixed_light_count: 'Low energy fixed lighting outlets',
        low_energy_lighting: 'Low energy lighting',
        roof_description: 'Roof description',
        roof_energy_eff: 'Roof energy efficiency',
        roof_env_eff: 'Roof environmental efficiency',
        walls_description: 'Walls description',
        walls_energy_eff: 'Walls energy efficiency',
        walls_env_eff: 'Walls environmental efficiency',
      },
      crime: {
        header: 'Crime',
        footer: 'Here is a breakdown of the crimes committed in the local area of your property.',
        noCrime: 'No crime was recorded during this period.',
        disclaimer:
          'Crime data is provided for information purposes only and shows crime within 200m of the address provided over the last 6 months',
      },
      profession: {
        header: 'Profession',
        footer: 'Here is a breakdown of the professions of people living in the local area of your property.',
      },
      propertyType: {
        header: 'Property Type',
        footer: 'Here is a breakdown of the types of properties in the local area of your property.',
      },
      tenure: {
        header: 'Tenure',
        footer: 'Here is a breakdown of the types of property tenure in the local area of your property.',
      },
      geology: {
        header: 'Geology',
        explainerBoxHeader: 'What is a GeoSure report?',
        explainerBox: `<p>The GeoSure data sets and reports from the British Geological Survey provide information about potential ground movement or subsidence in a helpful and user friendly format. The reports can help inform planning decisions and indicate causes of subsidence.</p>`,
      },
      flood: {
        header: 'Flood risk',
        explainerBoxHeader: 'What if I am at risk of floods?',
        explainerBox: `<p>The possibility of flooding need not necessarily stop you buying your dream home, but you should be aware of the risks.</p>{br}<p>Your lender may require adequate buildings insurance to be in place from exchange of contracts and you should satisfy yourself that you can obtain sufficient cover at a reasonable cost. It is best you discuss this with your solicitor as soon as possible.</p>`,
      },
      radon: {
        header: 'Radon',
        explainerBoxHeader: 'Should I worry about Radon Gas?',
        explainerBox: `<p>Just like you would be cautious if a structural report came back highlighting some potential issues, if a radon risk report comes back with potentially high readings it simply means that you have been made aware of any potential risks which you should try to resolve before continuing any further.</p>{br}<p>High levels of radon gas are influenced by the underlying geology in the area, as well as other factors including the way in which a building has been constructed and the habits of the occupants – heating and ventilation for example.</p>{br}<p>The average UK home has radon levels of 20 Bq/m3 (Becquerels per cubic metre of air) whereas the average background level outside is 4 Bq/m3</p>`,
      },
      infrastructure: {
        header: 'Infrastructure Projects',
        explainerBoxHeader: 'What if my property is in a HS2 zone?',
        explainerBox: `<p>How your home is affected depends on the location of your property and which phase of HS2 affects you.</p>{br}<p>You can use HS2's official website (www.hs2.org.uk) to explore the route. You can also contact their help desk to find out precise location information for your property in relation to the route.</p>`,
      },
    },
    conveyancing: {
      title: 'Choosing the right conveyancer matters',
      mainContentTitle: 'Make the right choice with Premier Property Lawyers',
      mainContent: {
        intro:
          "We have teamed up with Acre and Premier Property Lawyers to bring you access to the UK's largest and most respected conveyancing firm at a great, inclusive price.",
        second:
          "Buying or selling a property is one of the most significant financial decisions you'll ever make and property law can be complex. A competent conveyancer navigates these complexities, safeguarding your interests throughout the transaction.",
        whatDoYouGet: 'What do you get?',
        breakdown: `<ul><li><b>1. Dedicated conveyancer:</b> You will be assigned your own dedicated conveyancer so there is always someone on hand to guide and assist you through the entire process.</li><li><b>2. Straightforward pricing:</b> One fee, including local, water and environmental searches, mortgage fee, archiving and funds transfer.</li><li><b>3. Online case management</b>: PPL's revolutionary online service let's you manage your case whenever you want, from wherever you are, using a smartphone or your PC.</li><li><b>4. Unrivalled expertise:</b> We ensure that your dedicated conveyancer has the specialist knowledge and expertise required to manage the complexities of your case.</li></ul>`,
      },
      blackCardTitle: 'Your quote will include:',
      blackCardContent:
        '<ul><li>Standard property search pack, including water and environmental searches</li><li>Identity and funds checks</li><li>Mortgage fee</li><li>All standard legal fees</li><li>Leasehold fee is applicable</li><li>VAT</li></ul>{br}<p>Download your quote here to read all the terms and conditions and a full outline of the PPL pricing.</p>{br}<p>Answer some simple questions to access your instant quote.</p>',
      warningBoxTitle: 'Beware of internet conveyancers!',
      warningBox: `You may see some unbelievably low prices on the internet for conveyancing services. These are, in most cases, literally too good to be true and translate into a myriad of unexpected charges, surprises and slow service.`,
      warningBox2: `Unlike this inclusive quote, they probably don't include things as simple as VAT, basic searches and dealing with a mortgage lender and can often end up costing nearly three times as much as the headline fee.`,
      disclaimer:
        'Premier Property Lawyers Limited registered office, 1 Frances Way, Leicester, LS19 1H3. Practice Licence number 11351. Registered in England and Wales 04323405. VAT number 231 822924. The introducer Acre Platforms Limited who has provided this quotation has suggested that you use a particular Conveyancer for your conveyancing. The introducer is required to advise you that it has a financial arrangement with the Conveyancer and that the Conveyancer will pay a referral fee of up to £400.00 x VAT to the Introducer. The Conveyancer is totally independent from the introducer and the Conveyancer will act only on your instructions and always in your best interests. The Conveyancer will provide you with impartial independent advice when providing this service and you are able to raise any questions with them about any aspect of your case. We will only charge you our fee if you move for any reason your sale or purchase falls through we will not charge you anything for our time with the exception of the File opening Fee which is payable once a sale or purchase has been agreed subject to contract. If we have paid out any of the additional costs listed above on your behalf we will still need payment for these. Any upfront payments are not refundable but will be credited against costs incurred.',
      quoteButton: 'Get an instant quote',
      acceptMyQuote: 'Accept my quote',
      viewMyQuote: 'View my quote',
      haveQuestions: 'I have questions',
      leaseType: 'Tenure',

      leaseTypes: {
        freehold: 'Freehold',
        leasehold: 'Leasehold',
        flyingFreehold: 'Flying freehold',
        commonhold: 'Commonhold',
        feudal: 'Feudal',
        feuhold: 'Feuhold',
        crossoverLease: 'Crossover Lease',
      },
      locationType: 'Location',
      locationTypes: {
        englandWales: 'England and Wales',
        scotland: 'Scotland',
        northernIreland: 'Northern Ireland',
        england: 'England',
        wales: 'Wales',
      },
      navItems: {
        yourProperty: 'Your property',
        newProperty: 'Your new property',
        aboutYou: 'About you',
        yourMortgage: 'Your mortgage',
      },
      completion: {
        title: 'Your personalised quote',
        desc: `That's all we needed to know! You can now use the buttons below to download your personalised quote or return to your dashboard.`,
        viewAnswers: 'Return to dashboard',
        continueToNextStep: 'View your quote',
      },
      acceptQuote: {
        accept: `You've accepted your quote!`,
        explanation: 'Someone will get in touch via telephone call shortly regarding next steps.',
      },
      questions: {
        title: `Don't worry!`,
        explanation: 'Someone will get in touch via telephone call shortly regarding your conveyancing quote.',
      },
      blackCardQuote: {
        quoteSummary: 'Your inclusive price',
        title: 'Your conveyancing quote',
        desc: `In order to instruct your conveyancer and for them to start work on your case, you need to accept your quote by pressing the "Accept my quote" button below.`,
        desc2: `If you're not sure about something within your quote, click "I have questions" and we will ask PPL to get in touch.`,
        price: `{price} including VAT`,
      },
      navigationTitle: 'Conveyancing Quote',
    },
    tradesmen: {
      title: 'Find a local tradesperson to help you get your job done',
      mainContent: {
        howItWorks: 'How it works',
        breakdown: `<ul><li><b>1. Create a job for free.</b>  Whatever you need doing around the house, you'll get connected with the right tradesperson. With over 30 specialist trades, covering the whole of the UK, every job's covered. Just give as much detail as possible to start getting accurate quotes.</li><li><b>2. Get quotes.</b> You'll receive real quotes from up to 3 real tradespeople in the Rated People network.</li><li><b>3. Check ratings.</b> You'll get a link to each tradesperson's profile page where you can see their ratings and reviews.</li><li><b>4. Pick your tradesperson.</b>  After reviewing each tradesperson's qualifications, reputation you can decide who you want to go with, before agreeing the work with them.</li></ul>`,
      },
      blackCardTitle: 'Over 10,000 trusted tradespeople ready to get your job done',
      blackCardContent: `We've partnered with Rated People to give you access to their huge network of trusted tradespeople. Whether you're planning a large scale renovation or need help with smaller jobs around the home, you can find exactly the right person for your job.`,
      cta: 'Find a tradesperson',
    },
    homebuyersChecklist: {
      header: 'The home buying process',
      subHeader:
        'Thinking about buying a home? Purchasing a property can be a confusing process. Use our home buying checklist to answer any questions you may have.',
      checklist: {
        header: 'My checklist',
        mortgageAdvice: 'Getting qualified mortgage advice',
        findProperty: 'Find your perfect property',
        listYourHome: 'List your house for sale',
        conveyancer: 'Instruct a conveyancer',
        survey: 'Getting a survey done',
        mortgageApplication: 'Submitting a mortgage application',
        insurance: 'Get insured!',
        mortgageOffer: 'Receive your mortgage offer',
        completion: 'Completion!',
        wills: 'Make sure your Will is in order',
        moving: 'Time to get Moving',
        newHome: 'Settling into your new home',
        houseForSale: 'List your house for sale',
        goodbyeHome: 'Say goodbye to your old house',
        updateHouse: 'Updates to your house',
      },
      sections: {
        mortgageAdvice: {
          qualifiedMortgageAdviceHeader: 'Getting qualified mortgage advice',
          qualifiedMortgageAdvice1:
            '<p>If you want to make the process of buying a house easier, getting a mortgage with the help of a mortgage broker is the most obvious path. They can provide qualified mortgage advice and assist you with submitting your mortgage application. Mortgage brokers have industry knowledge and understand lender criteria, which can help you make the right choice and save time and hassle during the process.</p>',
          qualifiedMortgageAdviceQuote: `<p><b>A mortgage is one of the biggest financial responsibilities you will take on in your lifetime, if not the biggest, so it's important to ensure that it's done properly.</b></p>`,
          qualifiedMortgageAdvice2:
            '<p>Your mortgage broker will give you a clear idea of how much you can borrow (subject to credit checks and lender underwriting). Online calculators base this calculation on your income, but they make assumptions based on your employment type and frequency of income without verifying your figures.</p>',
          qualifiedMortgageAdvice3:
            '<p>By reviewing your payslips or proof of income documents, a mortgage broker can provide you with a much more accurate borrowing amount, as well as getting you a Decision in Principle.</p>',
          qualifiedMortgageAdviceExplainerBoxHeader: 'Decision in principle',
          qualifiedMortgageAdviceExplainerBoxContent: `<p>A Decision in Principle (DIP) helps you understand how much you could borrow before applying for a mortgage. It's also known as a Mortgage Promise or an Agreement in Principle, and is often seen as the first step when buying or remortgaging a home.</p>{br}<p>A Decision in Principle is provided directly by the lender and is obligation-free. It usually only involves a soft credit check, which means there is no impact on your credit score.</p>{br}<p>It's important to provide accurate information to your broker, as they will supply the lender with your income details, outgoings, existing credit agreements, and your last three years' address history.</p>`,

          qualifiedMortgageAdvice4:
            '<p>Once you receive your Decision in Principle, you can add the amount to your deposit and determine your budget for purchasing a house. This makes shopping for your new home much easier, as you know what you can afford. It can also help you agree on a better price with the seller, as it shows that you are a serious buyer who can get a mortgage.</p>',
        },
        mortgageAdviceHouseMove: {
          qualifiedMortgageAdviceHeader: 'Getting qualified mortgage advice',
          qualifiedMortgageAdvice1:
            '<p>If you want to make the process of moving house easier, getting a mortgage with the help of a mortgage broker is the most obvious path. They can provide qualified mortgage advice and assist you with submitting your mortgage application. Mortgage brokers have industry knowledge and understand lender criteria, which can help you make the right choice and save time and hassle during the process.</p>',
          qualifiedMortgageAdvice2: `<p>As you know a mortgage is one of the biggest financial responsibilities you will take on in your lifetime, if not the biggest, so it's important to ensure that it's done properly.</p>`,
          qualifiedMortgageAdvice3:
            '<p>Your mortgage broker will give you a clear idea of how much you can borrow (subject to credit checks and lender underwriting). They can advise you on what is best for you, whether that be porting your existing mortgage or borrowing more with a new lender.</p>',
          qualifiedMortgageAdviceExplainerBoxHeader: 'What is porting your mortgage',
          qualifiedMortgageAdviceExplainerBoxContent: `<p>Porting your mortgage deal means staying with your existing lender. It can be a good money saving option especially if you are part way through a deal which carries exit fees and early repayment charges since you could avoid having to pay (or at least be refunded for) these when you move.</p>{br}<p>Porting a mortgage deal follows the same process as switching to a new deal since, in effect, you are asking your lender to lend you the same money again to purchase your new property.</p>`,

          qualifiedMortgageAdvice4:
            '<p>Once you receive your Decision in Principle, you can add the amount to your deposit and determine your budget for purchasing a house. This makes shopping for your new home much easier, as you know what you can afford. It can also help you agree on a better price with the seller, as it shows that you are a serious buyer who can get a mortgage.</p>',
        },
        mortgageAdviceRemortgage: {
          qualifiedMortgageAdviceHeader: 'Getting qualified mortgage advice',
          qualifiedMortgageAdvice1:
            '<p>If you want to make the process of moving house easier, getting a mortgage with the help of a mortgage broker is the most obvious path. They can provide qualified mortgage advice and assist you with submitting your mortgage application. Mortgage brokers have industry knowledge and understand lender criteria, which can help you make the right choice and save time and hassle during the process.</p>',
          qualifiedMortgageAdvice2: `<p>As you know a mortgage is one of the biggest financial responsibilities you will take on in your lifetime, if not the biggest, so it's important to ensure that it's done properly.</p>`,
          qualifiedMortgageAdvice3:
            '<p>Your mortgage broker will give you a clear idea of how much you can borrow (subject to credit checks and lender underwriting). They can advise you on what is best for you, whether that be porting your existing mortgage or borrowing more with a new lender.</p>',
          qualifiedMortgageAdviceExplainerBoxHeader: 'What is a product transfer',
          qualifiedMortgageAdviceExplainerBoxContent: `<p>With a mortgage product transfer you're able to change your current mortgage product to a new one with the same lender. You may choose to do this when your current mortgage deal is coming to an end or if you're looking to lend more money using your house as security.</p>`,
        },
        perfectProperty: {
          perfectPropertyHeader: 'Find your perfect property',
          perfectProperty1: `<p>The first step to purchasing your dream home is finding it! This can sometimes be a long and drawn out process, but it doesn't have to be. The first thing to do is draw up a list of your non-negotiable features your new home must have. For instance, how near you are to your transport links, if it has a garden or how many bedrooms you need.</p>`,
          perfectProperty2: `<p>Once you have decided on these, you can begin your online search. Look in different areas of interest as well as their surroundings to get an idea of what is available on the market. This is also a good time to make some enquiries with local estate agents to get their help with your search and see some properties in person. When viewing properties, it is important not to get carried away and to remember your non-negotiables.</p>`,
          perfectProperty3:
            '<p>If you think you like a property enough to put an offer in, you do this via your estate agent. They will then contact the sellers (which can often be referred to as the Vendors) and put your offer forward.</p>',
          perfectPropertyExplainerBoxHeader: 'What is a memorandum of sale?',
          perfectPropertyExplainerBoxContent: `<p>A memorandum of sale is a legal document that records the details of a property sale. It is used to record that the sale of a property has been agreed upon between the buyer and seller, subject to contract.</p>{br}<p>Your Estate Agent will send the memorandum of sale to all parties involved and once the conveyancing solicitors receive it, they can begin the conveyancing process.</p>{br}<p>After a Memorandum of Sale has been drawn up between the buyer and seller of a property, you may see the term 'Sold STC' used on the estate agent's website. This means 'Sold, Subject to Contracts'.</p>`,

          perfectProperty4: `<p>It's a good idea to keep your estate agent informed as much as possible from this point in the home buying process. They may be trying to get updates from your solicitor and the Vendor's solicitor, and by keeping them in the loop, you can save them the hassle. It also demonstrates your enthusiasm for purchasing the property, and helps them keep the seller updated.</p>`,
        },
        perfectPropertyHouseMove: {
          perfectPropertyHeader: 'Find your perfect property',
          perfectProperty1: `<p>The first step to purchasing your dream home is finding it! This can sometimes be a long and drawn out process, but it doesn't have to be. The first thing to do is draw up a list of your non-negotiable features your new home must have. For instance, how near you are to your transport links, if it has a garden or how many bedrooms you need.</p>`,
          perfectProperty2: `<p>Once you have decided on these, you can begin your online search. Look in different areas of interest as well as their surroundings to get an idea of what is available on the market. This is also a good time to make some enquiries with local estate agents to get their help with your search and see some properties in person. When viewing properties, it is important not to get carried away and to remember your non-negotiables.</p>`,
          perfectProperty3:
            '<p>If you think you like a property enough to put an offer in, you do this via your estate agent. They will then contact the sellers (which can often be referred to as the Vendors) and put your offer forward.</p>',
          perfectPropertyExplainerBoxHeader: 'What is a memorandum of sale?',
          perfectPropertyExplainerBoxContent: `<p>A memorandum of sale is a legal document that records the details of a property sale. It is used to record that the sale of a property has been agreed upon between the buyer and seller, subject to contract.</p>{br}<p>Your Estate Agent will send the memorandum of sale to all parties involved and once the conveyancing solicitors receive it, they can begin the conveyancing process.</p>{br}<p>After a Memorandum of Sale has been drawn up between the buyer and seller of a property, you may see the term 'Sold STC' used on the estate agent's website. This means 'Sold, Subject to Contracts'.</p>`,
          perfectPropertyExplainerBoxHeader1: 'Decision in principle',
          perfectPropertyExplainerBoxContent1: `<p>A Decision in Principle (DIP) helps you understand how much you could borrow before applying for a mortgage. It's also known as a Mortgage Promise or an Agreement in Principle, and is often seen as the first step when buying or remortgaging a home.</p>{br}<p>A Decision in Principle is provided directly by the lender and is obligation-free. It usually only involves a soft credit check, which means there is no impact on your credit score.</p>{br}<p>It's important to provide accurate information to your broker, as they will supply the lender with your income details, outgoings, existing credit agreements, and your last three years' address history.</p>`,
          perfectProperty4: `<p>It's a good idea to keep your estate agent informed as much as possible from this point in the home buying process. They may be trying to get updates from your solicitor and the Vendor's solicitor, and by keeping them in the loop, you can save them the hassle. It also demonstrates your enthusiasm for purchasing the property, and helps them keep the seller updated.</p>`,
          perfectProperty5: `<p>Once you receive your Decision in Principle, you can add the amount to your deposit and determine your budget for purchasing a house. This makes shopping for your new home much easier, as you know what you can afford. It can also help you agree on a better price with the seller, as it shows that you are a serious buyer who can get a mortgage.</p>`,
        },
        conveyancing: {
          conveyancingHeader: 'Instruct a conveyancer',
          conveyancing1: `<p>A conveyancer, also known as a conveyancing solicitor or conveyancing lawyer, is a legal professional who specialises in the transfer of property ownership from one party to another. When buying a house, a conveyancer is necessary to ensure that the legal transfer of ownership is carried out correctly, as well as to conduct property searches and checks, and to handle the complex legal paperwork involved in the process.</p>`,
          conveyancing2: `<p>The role of the conveyancer is to ensure that the property you are buying has a clear title and that there are no outstanding mortgages or other financial obligations on the property. They will also ensure that all the necessary legal documents are in place and that the transfer of ownership is registered with the appropriate authorities.</p>`,
          conveyancing3: `<p>In addition to handling the legal aspects of the purchase, a conveyancer can also advise you on any potential issues that may arise during the process, such as problems with the property's boundaries or planning permissions. They can also provide guidance on related matters, such as property surveys and building regulations.</p>`,
          conveyancing4: `<p>Overall, a conveyancer is essential when buying a house to ensure that the transaction is legally binding and that you are protected from any potential legal or financial issues that may arise in the future.</p>`,
          cardHeader: 'Conveyancing',
          cardSubHeader: `Use the UK's leading independent conveyancing group`,
          cardButton: 'Get started',
        },
        conveyancingRemortgage: {
          conveyancingHeader: 'Instruct a conveyancer',
          conveyancing1: `<p>A conveyancer, also known as a conveyancing solicitor or conveyancing lawyer, is a legal professional who specialises in the transfer of property ownership from one party to another.</p>`,
          conveyancing2: `<p>You do still require a conveyancer when remortgaging your property, due to the complex legal process behind the remortgage. They will ensure that the mortgage is registered against the legal title of your house with the Land Registry.</p>`,
          conveyancingBoxHeader: 'My lender has offered me free legal',
          conveyancingBoxContent: `<p>You may have been offered free legals by your prospective mortgage lender. This means, they are covering the cost of your conveyancer as part of your application.</p>{br}<p>Using these conveyancers can be appealing as it lowers your upfront costs. They can however, be challenging as you do not normally have much visibility of your case. It may mean you have to chase them via your mortgage broker and can be left in the dark. </p>`,
          conveyancing3: `<p>In a remortgage, the role of the conveyancer is to redeem the existing charge on the property from your old lender. They then register the new charge and facilitate the transfer of funds from the new lender to the old one.</p>`,
          conveyancing4: `<p>Overall, a conveyancer is essential when remortgaging to ensure that the transaction is legally binding and that you are protected from any potential legal or financial issues that may arise in the future.</p>`,
          cardHeader: 'Conveyancing',
          cardSubHeader: `Use the UK's leading independent conveyancing group`,
          cardButton: 'Get started',
        },
        survey: {
          surveyHeader: 'Getting a survey done',
          survey1: `<p>Getting a building survey when buying a property is important because it provides a detailed and independent assessment of the property's condition. This information can help you make an informed decision about purchasing the property. It's important to note that a building survey is not the same as a lender's valuation, as they serve different purposes.</p>`,
          surveyExplainerBoxHeader: `What is a lender's valuation?`,
          surveyExplainerBoxContent: `<p>A lender's valuation is an assessment of the value of a property that you are trying to purchase conducted by your mortgage lender (sometime for a small fee). The purpose of the valuation is to help your lender establish the property's value relative to the sale price. The lender wants to ensure that the property is valuable enough to secure your mortgage.</p>{br}<p>This type of valuation can take up to 2 weeks.</p>`,
          survey2:
            '<p>A building survey can uncover any defects or issues with a property that may not be visible to an untrained eye. This could include problems with the structure of the building, the roof, the electrical wiring, the plumbing, or other areas that may be expensive to repair. This is especially important if the property you are purchasing is over 100 years old or has an unusual construction.</p>',
          survey3: `<p>Your building survey will provide a detailed analysis of the property's condition, including the age and type of construction, any signs of damp or other problems, and any repairs that have been carried out.</p>`,
          survey3a: `<p>If the property you're purchasing is less than 20 years old, the RICS (Royal Institute of Chartered Surveyors) don't normally recommend a survey. If you're purchasing a new build you would normally talk to a snagger.</p>`,
          survey4:
            '<p>This will allow you to budget accordingly and you may be able to negotiate a lower purchase price or ask the seller to carry out repairs before completing the purchase. The last thing you want when moving into your new home is to find out that you will have to pay for any immediate repairs out of pocket.</p>',

          surveyQuote:
            '<p><b>A good survey should also provide you with an estimate of the cost of any repairs needed. </b></p>',
          survey4a:
            '<p>A good survey should also provide you with an estimate of the cost of any repairs needed. This will allow you to budget accordingly and you may be able to negotiate a lower purchase price or ask the seller to carry out repairs before completing the purchase. The last thing you want when moving into your new home is to find out that you will have to pay for any immediate repairs out of pocket.</p>',
          survey5:
            '<p>Overall, a building survey is an important step in the property buying process. It helps you make an informed decision about whether to proceed with the purchase, provide you with valuable information to negotiate a fair price, and plan for any repairs or maintenance that may be required.</p>',
          cardHeader: 'Get an RICS-approved survey for your new home',
          cardSubHeader: `Acre Surveying will help you get the correct survey type for your new home at a great price.`,
          cardButton: 'Get started',
        },
        submittingMortgageApplication: {
          submittingMortgageApplicationHeader: 'Submitting a mortgage application ',
          submittingMortgageApplication1: `<p>This is a very exciting time, it's time for your mortgage broker to submit your mortgage application!</p>`,
          submittingMortgageApplication2: `<p>First things first, your mortgage lender will conduct a credit check to assess your creditworthiness and determine your ability to repay the mortgage. This is nothing to worry about, they need to ensure that you won't get into financial duress by taking out a mortgage and it's for your long-term benefit.</p>`,
          submittingMortgageApplication3: `<p>Next, they will verify your income to make sure that you can afford the mortgage payments. This may involve reviewing your employment history and income documents, such as your payslips.</p>`,
          submittingMortgageApplication4:
            '<p>And then, as mentioned in the Survey section above, the lender will complete their Valuation.</p>',
          submittingMortgageApplicationExplainerBoxHeader: 'What is a down valuation?',
          submittingMortgageApplicationExplainerBoxContent: `<p>A Down Valuation occurs when the property valuation shows that its value is less than the price you offered to pay for it. If this happens, you should speak to your Estate Agent about potentially renegotiating the purchase price.</p>{br}<p>Appealing a down valuation and getting a different result is very rare. If you haven't already done so, it may be worth undertaking a building survey or finding a new property.</p>`,

          submittingMortgageApplication5: `<p>Once all the necessary information has been collected, the lender's underwriting team will review the application and make a decision about whether or not to approve the mortgage. This can take some time as they have a lot of information to cover. Your mortgage broker will be in regular touch with them and will be able to provide you with updates on your application.</p>`,
          submittingMortgageApplication6: `<p>If your application is approved, you will typically receive a conditional approval letter outlining the terms and conditions of the mortgage. This will allow you to proceed with the completion process, where you will sign the necessary paperwork and receive the funds to purchase the property!</p>`,
          submittingMortgageApplication7: `<p>Throughout this process, your mortgage broker will work with you to ensure that all the necessary information is provided and that the application is processed as quickly and efficiently as possible. They will also keep you updated on the status of your application and any conditions that need to be met before final approval can be granted.</p>`,
        },
        insurance: {
          insuranceHeader: 'Get Insured!',
          insurance1: `<p>When you have a mortgage, you will need buildings insurance. Mortgage providers require you to have buildings insurance that covers the total value of your mortgage. You will be in default of your mortgage unless you have the correct insurance. </p>`,
          insurance2: '<p>Buying a house can be an extremely busy time and sometimes the basics can be forgotten. </p>',
          insurance3: `<p>Making sure your home is fully protected if something were to go wrong is of the upmost importance. </p>`,
          insurance4:
            '<p>Buildings insurance covers the cost to repair damage to or rebuild the structure of your home. This includes its windows, walls roof, outbuildings, and fitted kitchens and bathrooms. It provides cover for damage caused by events like fires, storms and floods.</p>',
          insurance5: `<p>Buildings insurance does not cover the items inside your house, like furniture, carpets, clothes, electronics and personal items. You'll need contents insurance to insure these. Limits, terms and exclusions apply to all buildings insurance.</p>`,
          cardHeader: 'Get insured',
          cardSubHeader: `Speak to your broker about insurance today`,
          cardButton: 'Message my broker',
        },
        mortgageOffer: {
          mortgageOfferHeader: 'Receive your mortgage offer',
          mortgageOffer1:
            '<p>Sometimes it can feel like a lifetime before you get your mortgage offer in your hand! Once you have that offer, things tend to move quite quickly. </p>',
          mortgageOfferQuote:
            '<p><b>Mortgage offers are normally valid for 6 months, so it is now time to start planning the exciting part - moving in!</b></p>',
          mortgageOffer2:
            '<p>Your mortgage offer will be sent to your conveyancer and mortgage broker, they will review all the information and answer any questions you may have.</p>',
        },
        mortgageOfferHouseMove: {
          mortgageOfferHeader: 'Receive your mortgage offer',
          mortgageOffer1:
            '<p>Sometimes it can feel like a lifetime before you get your mortgage offer in your hand! Once you have that offer, things tend to move quite quickly. Your mortgage offer will be sent to your conveyancer and mortgage broker, they will review all the information and answer any questions you may have.</p>',
          mortgageOffer2:
            '<p>Your conveyancer will organise to exchange contracts with the sellers, they can also now start trying to set a completion date.</p>',
          mortgageOffer3:
            '<p>Mortgage offers are normally valid for 6 months, so it is now time to start planning the exciting part - moving in!</p>',
        },
        completion: {
          completionHeader: 'Completion!',
          completion1: `<p>Mortgage completion is the final stage of the mortgage process when all the necessary steps have been completed, and the property purchase is finalised.</p>`,
          completion2:
            '<p>Before the completion date, your mortgage lender will review and finalise all the necessary mortgage documents, including the loan agreement, mortgage deed, and any other legal paperwork.</p>',
          completion3: `<p>You will decide on a completion date with your conveyancer, who will need to agree it with the seller's conveyancer. Sometimes choosing a completion date can be challenging, especially if you are in a chain.</p>`,
          completionExplainerBoxHeader: `What does being 'in a chain' mean?`,
          completionExplainerBoxContent: `<p>This is when you are in a line of purchasers and sellers who form an imaginary "chain". </p>{br}<p>For example: In order for you to purchase and move into your new home, you need to sell your home and the seller needs to buy their new home. </p>{br}<p>Delays and hold-ups in the completion process can occur, even if the issue is further down the chain from your purchase. In these situations, you have to remain patient and trust that your conveyancer, who has likely seen this many times before, will work through it all.</p>`,

          completion4:
            '<p>On the completion date, your mortgage lender will transfer the agreed upon loan amount to the solicitor or conveyancer who is handling the transaction. This amount will be used to pay the seller of the property.</p>',
          completion4a: `<p>Your conveyancer will ensure that all legal aspects are in order. They will review the title deeds, complete the necessary land registry and tax filings, and ensure that the property is legally transferred to your name. Once all the legal and financial aspects are resolved, the property ownership will be transferred to your name, which typically involves updating the land registry records to reflect you as the new owner.</p>`,
          completion5: `<p>Your conveyancer will ensure that all legal aspects are in order. They will review the title deeds, complete the necessary land registry and tax filings, and ensure that the property is legally transferred to your name. </p>`,
          completionQuote:
            '<p><b>Once all the legal and financial aspects are resolved, the property ownership will be transferred to your name, which typically involves updating the land registry records to reflect you as the new owner.</b></p>',

          completion6: `<p>Your solicitor or conveyancer will handle the payment of any outstanding fees and charges related to the property purchase, such as stamp duty, legal fees, land registry fees, and any other agreed upon expenses. They will have agreed all this with you much earlier in the process, so none of this should be a surprise to you.</p>`,
          completion7: `<p>After completion, you will receive the keys to the property, allowing you to finally take possession of your new home!</p>`,
        },
        wills: {
          willsHeader: 'Make sure your Will is in order',
          wills1:
            '<p>Now that you own your property, it is important to have a will written. Nobody likes to think about it, but now that you have a large asset you need to consider what would happen if the worst were to happen.</p>',
          wills1a:
            '<p>You may already have a will as you are already a homeowner. It is important to have that will reviewed as you may now have more assets. Nobody likes to think about it, but you need to consider what would happen if the worst were to happen.</p>',
          wills2:
            '<p>Did you know that if you die without a will, intestacy laws will apply? Your property and estate may not all go to your partner, especially if you are not married. Additionally, without a will, it can take much longer to settle your estate.</p>',
          wills3: `<p>Consider establishing a lasting power of attorney to ensure that your finances are managed in the event you lose capacity due to illness, injury, or dementia. Without one, your finances would be frozen, even your partner would be unable to make decisions for you and would be prevented from negotiating your mortgage rate in the future. As a result, you would likely move onto your lender's standard variable rate.</p>`,
          cardHeader: 'Write your will',
          cardSubHeader: `Write your will in as little as 15 minutes, take care of what matters`,
          cardButton: 'Get started',
        },

        moving: {
          movingHeader: 'Time to get Moving',
          moving1: `<p>The time has come to move in! When moving house, there are several important things to organise to ensure a smooth transition.</p>`,
          moving2:
            '<p>You now live at a new address, so you need to notify relevant parties of your change of address, including the post office, government agencies, banks, insurance providers, utility companies, and any subscriptions or memberships you have.</p>',
          moving3: `<p>Before you leave your last home, arrange for the disconnection of utilities (electricity, gas, water). Make sure you don't forget to get a final meter reading, as you won't have access once you go!</p>`,

          moving4:
            '<p>You then need to organise the connection of utilities at your new property. Make sure to schedule these services for the appropriate dates to avoid any disruptions.</p>',
          moving5: `<p>Contact your internet service provider and telephone company to arrange for the transfer or installation of services at your new address. Consider any downtime during the transition and plan accordingly - unfortunately you might have a few days without internet if you don't plan ahead.</p>`,
          movingQuote:
            '<p><b>If you are hiring a moving company, book their services in advance. If you plan to handle the move yourself, arrange for a suitable vehicle and any necessary equipment or packing supplies.</b></p>',

          moving6: `<p>Start packing your belongings well in advance. Label boxes clearly with the contents and the room they belong to. This will make unpacking easier and more organised.</p>`,
          moving7: `<p>You may want to consider setting up a mail redirection service with your local postal service to ensure that any mail sent to your old address is forwarded to your new address.</p>`,
          cardHeader: `On the Move`,
          cardSubHeader: `Make the moving in process easier with help from the professionals`,
          cardButton: 'Get started',
        },
        movingHouseMove: {
          movingHeader: 'Time to get Moving',
          moving1: `<p>The time has come to move in! When moving house, there are several important things to organise to ensure a smooth transition.</p>`,
          moving2:
            '<p>You now live at a new address, so you need to notify relevant parties of your change of address, including the post office, government agencies, banks, insurance providers, utility companies, and any subscriptions or memberships you have.</p>',
          moving3: `<p>You will also need to alert your local council you have moved to their borough, they will then set you up for Council Tax. Make sure you tell your current borough you are leaving too! You should be able to do this all online on your council's website.</p>`,

          moving4:
            '<p>You then need to organise the connection of utilities at your new property. Make sure to schedule these services for the appropriate dates to avoid any disruptions.</p>',
          moving5: `<p>Contact your internet service provider and telephone company to arrange for the transfer or installation of services at your new address. Consider any downtime during the transition and plan accordingly - unfortunately you might have a few days without internet if you don't plan ahead.</p>`,
          moving6: `<p>If you are hiring a moving company, book their services in advance. If you plan to handle the move yourself, arrange for a suitable vehicle and any necessary equipment or packing supplies.</p>`,
          moving7: `<p>Start packing your belongings well in advance. Label boxes clearly with the contents and the room they belong to. This will make unpacking easier and more organised.</p>`,
          moving8: `<p>You may want to consider setting up a mail redirection service with your local postal service to ensure that any mail sent to your old address is forwarded to your new address.</p>`,
          cardHeader: `On the Move`,
          cardSubHeader: `Make the moving in process easier with help from the professionals`,
          cardButton: 'Get started',
        },
        goodbyeHome: {
          goodbyeHomeHeader: 'Say goodbye to your old house',
          goodbyeHome1: `<p>It is time to say goodbye to your old house, your new beginning is waiting!</p>`,
          goodbyeHome2: `<p>Before you leave your last home, arrange for the disconnection of utilities (electricity, gas, water). It is important as remember when you close the door for the final time you won't be able to pop back in!</p>`,
          goodbyeHome3: `<p>You may have agreed certain things you would amend or fix in your current property, so ensure you organise for this in due time. You do not want to annoy the buyers of your property by not holding up your end of the agreement.</p>`,

          goodbyeHome4:
            '<p>It is also nice to leave the property clean for the buyers, think about them opening the front door and it being sparkly and clean!</p>',
          goodbyeHomeQuote: `<p><b>Make sure you don't forget to get a final meter reading, your utility company may ask for it.</b></p>`,

          cardHeader: `Need a pro's help?`,
          cardSubHeader: `We've partnered with the countries top tradesmen.`,
          cardButton: 'Get started',
        },
        settlingIn: {
          settlingInHeader: 'Settling into your new home',
          settlingIn1: `<p>Now the dust has settled in your new home, you may start thinking about improvements you want to make to the property. It is important to not get overwhelmed, start by making a list of things you want to do and start a mood board for any inspiration on design changes.</p>`,
          settlingIn2:
            '<p>For added security, consider changing the locks on the doors of your new property. If there is a security system, arrange for the transfer of the service to your name and update any access codes or passwords.</p>',
          cardHeader: `Need a pro's help?`,
          cardSubHeader: `We've partnered with the countries top tradesmen.`,
          cardButton: 'Get started',
        },
        listYourHouse: {
          listYourHouseHeader: 'List your house for sale',
          listYourHouse1: `<p>Now the dust has settled in your new home, you may start thinking about improvements you want to make to the property. It is important to not get overwhelmed, start by making a list of things you want to do and start a mood board for any inspiration on design changes.</p>`,
          listYourHouse2:
            '<p>Research the local market and compare prices of similar properties in your area. You may want to consider speaking to an estate agent as soon as possible to determine the optimal listing price.</p>',
          listYourHouseQuote: `<p><b>Hopefully you are inundated with requests to view your property and have lots of enquires. It can take time to sell a property so don't be alarmed if you do not see immediate interest.</b></p>`,

          listYourHouse3: `<p>When you receive offers, evaluate them carefully. Consider the price, any limitations set by the seller, terms, and proposed completion date.</p>`,
          listYourHouse4: `<p>You can negotiate with potential buyers to secure a deal that aligns with your goals.</p>`,

          cardHeader: `Need a pro's help?`,
          cardSubHeader: `We've partnered with the countries top tradesmen.`,
          cardButton: 'Get started',
        },
        updateYourHome: {
          updateYourHomeHeader: 'Updates to your house',
          updateYourHome1:
            'Whether or not you remortgaged like for like or added on some additional funds to your mortgage, you might want to make some upgrades to your home. This can be overwhelming and you may not know where to start. Speaking to a professional and getting a clear idea on costs can help to inform your decisions.',
          cardHeader: `Need a pro's help?`,
          cardSubHeader: `We've partnered with the countries top tradesmen.`,
          cardButton: 'Get started',
        },
      },
    },
    docUpload: {
      title: 'Upload documents',
      unsupportedFileFormat: `Selected file format is not supported, the following formats are supported: ${supportedFileTypes}.`,
      noClientsError: 'At least 1 client must be selected before uploading this document',
      fileNotUploaded: 'File not uploaded',
      viewMyDocuments: 'View my documents',
      selectDocumentToUpload: 'Select document to upload',
      myDocuments: 'My documents',
      description: 'Description',
      selectClientAddress: 'Which address is this for?',
      documentOwner: 'Document owner',
      fileName: 'File name',
      fileType: 'File type',
      selectPlaceholder: 'Select clients',
      label: 'Clients',
      exceededMaxDocuments:
        'The maximum number of documents you can upload at a time is 10, please remove some documents to continue.',
      addressRequiredTitle: `Why can't I add my proof of address?`,
      addressRequiredDesc: 'We do not have a current address for you, please provide one in the About You form.',
      fileEmptyError: 'The file you are trying to upload is empty. Please check the file and try again.',
      summary: {
        subText: 'Uploaded by {name} • {date}',
        documents: 'Documents',
        checkList: 'Documents Checklist',
        allDocuments: 'All documents',
        desc: `Here's a list of documents we need in order for us to help you with your mortgage application.`,
        more: 'Learn more',
        id: 'Proof of ID',
        address: 'Proof of address',
        income: 'Proof of income',
        bankingStatement: 'Bank statement',
        additional: 'Additional documents',
        noDocs: 'No documents yet',
      },
      bankingStatement: {
        title: 'Bank statement',
        upload: 'Upload bank statements',
        listTitle: `There's a few ways you can find and upload your bank statements:`,
        online: 'Log into your online banking account and download your statement as a PDF. You can then upload this.',
        visitBranch:
          'Go to your local branch and ask them to print off your statement. Then you can take a picture of it or scan it, and upload that.',
      },
      proofOfId: {
        title: 'Proof of ID',
        desc: 'Please upload proof of ID',
      },
      proofOfIncome: {
        title: 'Proof of income',
        upload: 'Upload proof of income',
        listTitle: 'Depending on your type of employment you may have different proof of income, this will usually be:',
        threeMonthsPayslips: '3 months payslips',
        selfEmployed:
          'If you are self employed, the last 2 years Tax Calculations and corresponding Tax Year Overviews or last 2 years finalised accounts',
        limitedCompany:
          'If you are a Limited Company Director or a Salaried Director please speak to your Broker about what they require',
      },
      proofOfAddress: {
        title: 'Proof of address',
        upload: 'Upload proof of address',
        listTitle:
          'We require a proof of address, unless your broker has told you to use a specific document type you can normally use:',
        drivingLicense: 'Valid UK Driving Licence',
        utilityBill: 'Utility Bill',
        recentBankStatement: 'Most recent Bank Statement',
        councilTaxBill: 'Council Tax Bill',
        pensionStatement: 'Pension statement',
        investmentStatement: 'Investment statement',
      },
      other: {
        title: 'Additional documents',
        upload: 'Upload additional documents',
        desc: 'Sometimes you will need additional documents for your Mortgage Application, feel free to upload your Memorandum of Sale, your Annual Mortgage Statement or any other documents your Broker asks for!',
      },
      protection: {
        title: 'Your documents',
        upload: 'Upload documents',
        desc: `Sometimes you will need to provide documents for your Health and Protection Application. Your broker should let you know what documents you're required to upload.`,
      },
      proof: {
        which: 'Which document are you uploading?',
        tapToUpload: 'Tap to upload',
        clickToUpload: 'Click to upload',
        submit: 'Upload and continue',
        upload: 'Upload',
        skip: 'Skip for later',
        other: 'Document type',
        enterDocumentType: 'Enter document type',
      },
      saveDocumentsModal: {
        title: `Your files haven't uploaded`,
        desc: 'If you leave now, your files will not upload. Are you sure you want to do this?',
        exit: 'Exit',
        uploadFiles: 'Upload files',
      },
      types: {
        affordabilityScreenshot: 'Affordability',
        agreementInPrinciple: 'Agreement in principle',
        applicationLetter: 'Mortgage Application Form',
        bankStatement: 'Bank statement',
        birthCertificate: 'Birth certificate',
        clientPortalIdd: 'Client Portal IDD',
        creditClientPortalIdd: 'Credit Disclosure',
        companyAccounts: 'Company accounts',
        customerCommunication: 'Customer communication',
        creditReport: 'Credit report',
        debtConsolidationComparisonForm: 'Debt consolidation comparison form',
        debtConsolidationSupplement: 'Debt consolidation supplement',
        driversLicenseBack: 'Drivers license back',
        driversLicenseFront: 'Drivers license front',
        esis: 'ESIS',
        factFind: 'Fact Find',
        idd: 'IDD',
        creditIdd: 'Credit Disclosure',
        importedEsis: 'Imported ESIS',
        importedIdd: 'Imported IDD',
        importedSuitabilityReport: 'Imported Suitability Report',
        insuranceApplication: 'Insurance application',
        insuranceIllustration: 'Insurance illustration',
        insuranceComparisonReport: 'Insurance comparison report',
        insuranceKeyFacts: 'Insurance key facts',
        internal: 'Internal',
        investmentStatement: 'Investment statement',
        letterOfGift: 'Letter of gift',
        livePhoto: 'Live photo',
        mortgageApplication: 'Mortgage application',
        mortgageStatement: 'Mortgage statement',
        nationalIdentityCard: 'National identity card',
        nationalIdentityCardFront: 'National ID card front',
        nationalIdentityCardBack: 'National ID card back',
        networkPrivacyNotice: 'Network Privacy Notice',
        offerLetter: 'Mortgage Offer letter',
        organisationPrivacyNotice: 'Privacy Notice',
        other: 'Other',
        p60: 'P60',
        passport: 'Passport',
        payslip: 'Payslip',
        pensionStatement: 'Pension statement',
        policyLetter: 'Policy letter',
        propertyTitle: 'Property Title',
        propertyTitlePlan: 'Title Plan',
        protectionSuitabilityReport: 'Health and Protection Suitability Report',
        sa302: 'SA302',
        suitabilityReport: 'Suitability Report',
        template: 'Template',
        templateComplianceEmail: 'Template compliance email',
        thirdPartyCommunication: 'Third party communication',
        thirdPartyPrivacyNotice: 'Third party privacy notice',
        utilityBill: 'Utility bill',
        verbalIdd: 'Verbal IDD',
        creditVerbalIdd: 'Verbal Credit Disclosure',
        statementOfNeeds: 'Statement of Needs',
        directDebitMandate: 'Direct Debit Mandate',
        legalCharge: 'Legal Charge',
        incomeDeclaration: 'Income Declaration',
        creditSearch: 'Credit Search',
        valuation: 'Valuation',
        mortgageQuestionnaire: 'Mortgage Questionnaire',
        consent: 'Consent',
        deedOfPostponement: 'Deed of Postponement',
        redemptionFigure: 'Redemption Figure',
        btlPortfolio: 'BTL Portfolio',
        lendingIntoRetirementDeclaration: 'Lending Into Retirement Declaration',
        occupiersConsentForm: 'Occupiers Consent Form',
        independentLegalAdviceForm: 'Independent Legal Advice Form',
        purposeOfLoanDeclaration: 'Purpose Of Loan Declaration',
        interestOnlyDeclaration: 'Interest Only Declaration',
        limitedCompanyAccounts: 'Limited Company Accounts',
        managementAccounts: 'Management Accounts',
        employmentContract: 'Employment Contract',
        affordabilityCalculator: 'Affordability Calculator',
        loanSettlementFigure: 'Loan Settlement Figure',
        creditCardStatement: 'Credit Card Statement',
        letterOfExplanation: 'Letter of Explanation',
        propertyLease: 'Lease',
        groundRentServiceChargeConfirmation: 'Ground Rent / Service Charge Confirmation',
        lastWillAndTestament: 'Last will and testament',
        declarationOfTrust: 'Declaration of trust',
        surveyReport: 'Survey report',
        powerOfAttorney: 'Power of Attorney',
        epcReport: 'Energy Performance Certificate (EPC)',
        visa: 'Visa / Indefinite leave to remain',
        taxYearOverview: 'Tax year overview',
        proofOfDeposit: 'Proof of deposit',
        esisGeneratedByLender: 'ESIS generated by Lender',
        memorandumOfSale: 'Memorandum of Sale',
        lenderEsis: 'Lender ESIS',
        callRecording: 'Call recording',
        externalEvidenceOfResearch: 'External evidence of research',
        costOfMove: 'Cost of move',
        budgetStressTest: 'Budget stress test',
        wellbeingAssessment: 'Wellbeing assessment',
        insuranceDeclaration: 'Insurance declaration',
        benefitReport: 'Benefit report',
        riskInformationPack: 'Risk information pack',
        equityReleaseFactFind: 'Equity release fact find',
        witnessDeclaration: 'Witness declaration',
        feeElection: 'Fee election',
      },
      complete: 'Complete',
      skip: 'Skip for later',
      verified: 'Verified',
      uploaded: 'Uploaded',
      uploadDocument: 'Upload document',
      removeDocument: 'Remove document',
      documentAlreadyAdded: 'Document has already been added',
      uploadFiles: 'Upload files',
      selectFileOwner: `Who's this file for?`,
    },
    select: {
      loading: 'Loading...',
      message: 'Select an Item',
    },
    nameForm: {
      title: `What's your full name?`,
      desc: 'Enter your name as it appears on your passport or driving licence',
      salutation: 'Title',
      yourSalutation: 'Your title',
      firstName: 'First name',
      yourFirstName: 'Your first name',
      middleName: 'Middle name',
      yourMiddleName: 'Your middle name',
      middlePlaceholder: 'Enter if you have any middle names',
      yourLastName: 'Your last name',
      surname: 'Surname',
    },
    nameChangeForm: {
      title: 'Name change',
      addNameChange: '+ Add name change',
      previousFirstName: 'Previous first name',
      previousSurname: 'Previous surname',
      dateOfNameChange: 'Date of name change',
      reasonForNameChange: 'Reason for name change',
    },
    mortgageTypeForm: {
      title: 'What are you looking to do?',
      purchased: `I'm buying a property`,
      houseMove: `I'm moving home`,
      remortgage: `I'm remortgaging`,
      BTL: `I'm purchasing a buy-to-let`,
      remortgageBTL: `I'm remortgaging a buy-to-let`,
    },
    conveyancerTypeForm: {
      title: 'Do you have a conveyancer?',
      accordion: {
        title: 'Why do I need a conveyancer?',
        description:
          'A conveyancer is a professional who handles all the legal aspects of buying a property, ensuring that the transaction is legally sound, transferring ownership, checking for any issues like unpaid property taxes or disputes, and handling contracts and paperwork, so you can confidently and smoothly purchase your property.',
      },
      description: `If you don't have a conveyancer yet, we can provide you with one. Simply select 'I need help finding a conveyancer' below and we'll put you in touch with an experienced professional at the right time.`,
      help: 'I need help finding a conveyancer',
      haveConveyancer: 'I already have a conveyancer',
      provided: 'My lender provides a conveyancer',
      needHelp: {
        title: `Don't worry, we can help you find one!`,
        description: `You'll be able to get a quote and speak to our award winning conveyancing partner from your dashboard after you finish this form.`,
      },
      firmName: 'Firm name',
      conveyancerName: `Conveyancer's name`,
      conveyancerEmail: `Conveyancer's email address`,
      conveyancerPhone: `Conveyancer's phone number`,
      dxNumber: 'DX number',
      dxTown: 'DX town',
      conveyancerCaseRef: `Conveyancer's case reference`,
    },
    mortgagePreferenceForm: {
      termTitle: 'How many years do you want your mortgage term to be?',
      termDesc: `This doesn't need to be final, you can leave it as 25 years and discuss it with your advisor later.`,
      termAccordionTitle: `Help with working out mortgage term`,
      termAccordionDesc: `<p>There's a few things to think about to help work out your mortgage term length:</p>{br}<ul><li>If you choose a shorter mortgage term, e.g. 20 years or under, your monthly repayments will be higher. But the amount of interest you pay in the long run will be less.</li>{br}<li>If you choose a longer mortgage term, e.g. 25 years or over, your monthly repayments will be lower. However, you'll pay more interest and spend more overall.</li></ul>`,
      fixedTermTitle: `How long would you like to fix your rate for?`,
      fixedTermDesc: `You can choose how long you want the initial fixed rate on your mortgage to run for. Once your mortgage deal has ended you'll have to remortgage, or you'll go on a standard variable rate.`,
      fixed24Months: '2 years',
      fixed36Months: '3 years',
      fixed60Months: '5 years',
      fixed120Months: '10+ years',
      other: 'Other',
      repayment: 'Repayment',
      interestOnly: 'Interest only',
      partAndPart: 'Part and part',
      fixed: 'Fixed',
      tracker: 'Tracker',
      discount: 'Discount',
      repaymentTitle: 'How do you want to repay your mortgage?',
      repaymentAccordionTitle: 'Mortgage types explained',
      repaymentAccordionDesc: `<p><strong>Repayment</strong> is where you pay back part of the mortgage and the interest, every month. If you meet all of your monthly repayments, you'll pay off the full loan by the end of the mortgage term. This is the most common option.</p>{br}<p><strong>Interest only</strong> means you'll only pay back the interest on the loan. This can make your repayments lower, but you'll still need to pay off the entire loan at the end of the mortgage term.</p>{br}<p><strong>Part and part</strong> is a combination of the other options. You'll pay back a set amount of interest and a portion of the loan each month. At the end of the mortgage term, you'll still have some capital to pay off.</p>`,
      rateTypeTitle: 'What kind of mortgage rate would you like?',
      rateTypeAccordionTitle: 'Mortgage rates explained',
      rateTypeAccordionDesc:
        '<p>When you get a mortgage you will normally be on an initial deal which can last between 2 and 10 years. There are a few different types of mortgages but the most common are Fixed and Tracker.</p>{br}<p><strong>A fixed rate</strong> means you have your interest rate locked in, this means you will have a set amount you pay each month for the duration of your fixed period.</p>{br}<p><strong>A tracker rate</strong> can have different monthly repayments as it tracks the Bank of England base rate. Your lender can therefore change your interest rate in line with any base rate changes.</p>',
      repaymentPercentageTitle: 'How much would you like to repay?',
      percent: 'Percent',
      amount: 'Amount',
    },
    borrowingNeedsForm: {
      title: 'How much do you need to borrow?',
    },
    propertyForm: {
      owned: 'Have you ever owned a property before?',
      foundProperty: 'Have you found a property to buy?',
      aboutProperty: `About the property you're buying`,
      yourProperty: 'Your property',
      addYourProperty: 'Add your property',
      propertyPrice: 'How much is this property going to cost?',
      purchasePrice: 'Original purchase price',
      currentValuation: 'Current valuation',
      propertyLetOut: 'Is this property rented out?',
      monthlyRentalIncome: `What's the monthly rental income?`,
      monthlyManagementExpenses: 'Monthly management fees',
      hasLived: 'Have you previously lived in the property?',
      willLive: 'Do you or any of your relatives plan to live in the property?',
      propertyType: {
        title: 'Property type',
        house: 'House',
        flat: 'Flat',
        maisonette: 'Maisonette',
        houseBoat: 'House boat',
        bungalow: 'Bungalow',
        numberOfBedrooms: 'Number of bedrooms',
        propertyParking: 'Parking',
      },
      parkingType: {
        none: 'None',
        driveway: 'Driveway',
        singleGarage: 'Single garage',
        doubleGarage: 'Double garage',
        parkingSpace: 'Parking space',
      },
      leaseType: 'Tenure',
      leaseTypes: {
        freehold: 'Freehold',
        leasehold: 'Leasehold',
        flyingFreehold: 'Flying freehold',
        commonhold: 'Commonhold',
        feudal: 'Feudal',
        feuhold: 'Feuhold',
        crossoverLease: 'Crossover Lease',
      },
      wallType: 'Wall type',
      wallTypes: {
        steel: 'Steel',
        cobb: 'Cobb',
        standardBrick: 'Standard brick',
        singleSkinBrick: 'Single skin brick',
        woolaway: 'Woolaway',
        cornishType1: 'Cornish type 1',
        cornishType2: 'Cornish type 2',
        otherConcrete: 'Other concrete',
        wimpeyNoFines: 'Wimpey no fines',
        laingEasiform: 'Laing easiform',
        timberFramed: 'Timber framed',
        stone: 'Stone',
        other: 'Other',
      },
      roofType: 'Roof type',
      roofTypes: {
        flatRoof: 'Flat roof',
        thatchedRoof: 'Thatched roof',
        tileRoof: 'Tile roof',
        slateRoof: 'Slate roof',
      },
      location: 'Property location',
      locationTypes: {
        englandWales: 'England and Wales',
        scotland: 'Scotland',
        northernIreland: 'Northern Ireland',
        england: 'England',
        wales: 'Wales',
      },
    },
    otherPropertiesForm: {
      accordionTitle: 'Why do we need this?',
      accordionDesc:
        'Your broker needs to know about other properties you own as this can change which lender they may recommend. Please include all properties you own, including rental properties or properties which are mortgage free.',
      hasOtherProperties: 'Do you own any other properties?',
      addNewProperty: '+ Add new property',
      propertyTitle: 'Property {index}',
      delete: 'Delete',
    },
    depositForm: {
      title: 'Your deposit sources',
      desc: 'Choose a source type for your deposit and add the amount. You can add as many deposit sources as you like.',
      accordionTitle: 'Why do we ask this?',
      accordionDesc: `Lenders want to know where your deposit came from and to check it's come from an approved source.`,
      source: 'Source',
      howMuch: 'How much?',
      cashSavings: 'Cash savings',
      investment: 'Investment',
      vendorInducement: 'Vendor inducement',
      other: 'Other',
      gift: 'Gift',
      addAnotherDeposit: '+ Add another source',
      addDeposit: '+ Add source',
      selectClient: 'Select a client',
    },
    titleForm: {
      title: 'Title',
      desc: 'How should we address you?',
      options: {
        title: 'Other',
        mr: 'Mr',
        mrs: 'Mrs',
        miss: 'Miss',
        ms: 'Ms',
        mx: 'Mx',
        dr: 'Dr',
        dame: 'Dame',
        prof: 'Prof',
        sir: 'Sir',
        lord: 'Lord',
        lady: 'Lady',
      },
    },
    dobForm: {
      title: `When's your birthday?`,
      desc: 'As it appears on your passport or driving licence',
      label: 'Date of birth',
    },
    emailForm: {
      title: `What's your email address?`,
      placeholder: 'e.g. client@email.com',
    },
    moveInDateForm: {
      title: `When did you start living at {address}?`,
      desc: 'Lenders require three years of address history.',
      label: 'When did you move into this address?',
    },
    moveOutDateForm: {
      title: `When did you stop living at {address}?`,
      desc: 'Lenders require three years of address history.',
      label: 'Move out date',
    },
    phoneForm: {
      title: `What's your mobile phone number?`,
      yourPhoneNumber: 'Your phone number',
      placeholder: 'e.g. 07123456789',
    },
    residentialStatusForm: {
      tenant: 'Tenant',
      owner: 'Owner',
      livingWithFamily: 'Living with family',
      label: `What's your residential status?`,
    },
    genderForm: {
      title: `What's your sex?`,
      accordionTitle: `Why do we ask this?`,
      accordionDesc: `Most lenders use systems that refer to the sex named on your passport. We understand that the choices here
      may not represent you as they should. You can select 'other' if you prefer not to say.`,
      male: 'Male',
      female: 'Female',
      other: 'Other',
    },
    maritalStatusForm: {
      title: `What's your relationship status?`,
      married: 'Married',
      single: 'Single',
      divorced: 'Divorced',
      separated: 'Separated',
      widowed: 'Widowed',
      cohabit: 'Cohabit',
      civilPartnership: 'Civil partnership',
    },
    dependantsForm: {
      title: `Is anybody financially dependent on you?`,
      count: `How many dependants do you have?`,
      relationship: `What's their relationship to you?`,
      name: `What's their full name?`,
      dob: `When were they born?`,
      dependant: `Dependant {number}`,
      child: 'Child',
      spouse: 'Spouse',
      parent: 'Parent',
      sibling: 'Sibling',
      other: 'Other',
      accordionTitle: `Who counts as a financial dependant?`,
      accordionDesc: `This includes anyone who relies on you financially, like children or any adults you're caring for.`,
    },
    nationalityForm: {
      title: `What's your nationality?`,
      placeholder: 'Search to select',
    },
    countryOfBirthForm: {
      title: `What was your country of birth?`,
    },
    nationalInsuranceForm: {
      title: `What's your National Insurance number?`,
      label: 'NI number',
      answerLater: `I'm not sure, I'll answer this later`,
      accordionTitle: `Where can I find my National Insurance number?`,
      accordionDesc: `<p>You can find your National Insurance number in your:<ul><li>Payslip</li><li>P60</li><li>Pension, tax or benefit letters</li><li>National Insurance section of your personal tax account</li></ul></p>`,
    },
    employmentForm: {
      startDate: 'Start date',
      endDate: 'End date',
      addCurrentEmployment: '+ Add current employment',
      addPreviousEmployment: '+ Add previous employment',
      isCurrentEmployment: 'Current employment',
      currentEmployment: 'Current employment {number}',
      previousEmployment: 'Previous employment {number}',
      expectedRetirementAge: 'Expected retirement age',
      employer: {
        employerName: 'Employer name',
        employerPhoneNumber: 'Employer phone number (optional)',
        employerEmail: 'Employer email address (optional)',
        employerAddress: 'Employer address',
        title: 'Employer details',
        accordionTitle: 'Why do we ask this?',
        accordionDesc: 'Some lenders may contact employers to get references when you apply for a mortgage.',
      },
      retired: {
        pensionTypePrivate: 'Private retirement',
        pensionTypeState: 'State retirement',
        pensionTypeWar: 'War disability',
        pensionTypeInjuriesDisablement: 'Industrial injuries disablement',
        pensionTypeWidows: 'Widows',
        pensionTypeOther: 'Other',
      },
      status: {
        title: `Employment status`,
        selectEmployed: 'Employed',
        selectSelfEmployed: 'Self-employed',
        selectRetired: 'Retired',
        selectHomemaker: 'Not in employment',
      },
      income: {
        title: `Salary details`,
        basicSalary: 'Annual salary before tax',
        bonus: 'Bonus (Per year)',
        commission: 'Commission (Per year)',
        overtime: 'Overtime (Per year)',
        other: 'Other income (Per year)',
      },
      type: {
        title: `Temporary contract`,
        selectPermanent: 'Permanent',
        selectFixedTerm: 'Contract',
      },
      basis: {
        title: `Employment type`,
        selectPartTime: 'Part time',
        selectFullTime: 'Full time',
      },
      title: {
        title: `Job title`,
        jobTitleLookup: 'Job title',
        jobTitleSpecific: 'Specific job title (optional)',
        accordionTitle: `What if my job isn't listed?`,
        accordionDesc:
          'Select the title closest one from the list and enter your specific job title below. You can discuss it with your advisor later.',
        accordionTitleSpecific: 'What is a specific job title?',
        accordionDescSpecific:
          'Some lenders ask for a specific job title for your role, this can normally be found on your contract of employment.',
      },
      incomeChange: {
        title: 'Are you expecting your income to change?',
        incomeIncreaseOrDecrease: 'Are you expecting your income to increase or decrease?',
        incomeChangeIncrease: 'Increase',
        incomeChangeDecrease: 'Decrease',
      },
      accountant: {
        hasAccountant: 'Do you have an accountant?',
        accountantName: 'Accountant name',
        phoneNumber: 'Accountant phone number',
        email: 'Accountant email',
      },
      accounting: {
        noOfYears: 'How many years of accounts do you have?',
        netProfitDetails: 'Net profit details',
        netProfitsLastYear: 'Net profit last year',
        netProfitsTwoYearsAgo: 'Net profit two years ago',
        netProfitsThreeYearsAgo: 'Net profit three years ago',
        taxableIncomeDetails: 'Taxable income details',
        taxableIncomeLastYear: 'Taxable income last year',
        taxableIncomeTwoYearsAgo: 'Taxable income two years ago',
        taxableIncomeThreeYearsAgo: 'Taxable income three years ago',
        percentageShareLastYear: 'Percentage share last year',
        percentageShareTwoYearsAgo: 'Percentage share two years ago',
        percentageShareThreeYearsAgo: 'Percentage share three years ago',
        shareholdingPercentage: 'What is your current percentage shareholding?',
        shareholdingDetails: 'Shareholding details',
        businessIncomeDetails: 'Business income details',
      },
      additionalEmployment: {
        haveAnotherJob: 'Do you have another job?',
        howManyJobs: 'How many other jobs do you have?',
      },
      pension: {
        title: 'Your pension details',
        type: 'Pension type',
        income: 'Gross annual pension income',
        provider: 'Pension provider',
      },
      pensionIncome: {
        grossAnnualPensionIncome: 'Gross annual pension income',
      },
      pensionProvider: {
        providerName: 'Provider name',
      },
      selfEmployedDetails: {
        businessName: 'Business name',
        businessTitle: 'Business details',
        businessAddress: 'Business address',
        dateEstablished: 'When were you first associated with the business?',
        dateCompleted: 'Date you ended your association with the business',
        employedForTaxPurposes: 'Is your client employed for tax purposes?',
        companyType: 'Company type',
        selectSoleTrader: 'Sole trader',
        selectLtd: 'Limited company',
        selectPartnership: 'Partnership',
        selectLlp: 'Limited Liability Partnership (LLP)',
      },
    },
    employmentStatus: {
      EMPLOYED: 'Employed',
      SELF_EMPLOYED: 'Self employed',
      RETIRED: 'Retired',
      HOMEMAKER: 'Not in employment',
    },
    pensionType: {
      INDUSTRIAL_INJURIES_DISABLEMENT: 'Industrial injuries disablement',
      PRIVATE_RETIREMENT: 'Private retirement',
      STATE_RETIREMENT: 'State retirement',
      WAR_DISABILITY: 'War disability',
      WIDOWS: 'Widows',
      OTHER: 'Other',
    },
    selfEmploymentType: {
      SOLE_TRADER: 'Sole trader',
      PARTNERSHIP: 'Partnership',
      LLP: 'LLP',
      PSC: 'Personal Service Company (PSC)',
      LTD: 'Limited Company (LTD)',
    },
    borrowing: {
      reviewBorrowingDetails: {
        reviewTitle: 'Your requirements',
        reviewSubTitle: 'Check your details are all filled in and correct',
        mortgageType: 'Mortgage type',
        borrowingNeeds: 'Borrowing needs',
        haveYouOwnedAProperty: 'Have you owned a property before?',
        deposits: 'Deposits',
        mortgageTerm: 'Mortgage term',
        fixedRate: 'Fixed rate',
        propertyAddressKnown: 'Do you know the address of the property you want to purchase?',
        purchase: 'Purchase',
        houseMove: 'House move',
        purchaseBTL: 'Purchase buy-to-let',
        remortgage: 'Remortgage',
        remortgageBTL: 'Remortgage buy-to-let',
        CASH_SAVINGS: 'Cash Savings',
        INVESTMENT: 'Investment',
        GIFT: 'Gift',
        OTHER: 'Other',
        VENDOR_INDUCEMENT: 'Vendor Inducement',
        INVALID_DEPOSIT_TYPE: 'Invalid Deposit Type',
        additionalBorrowing: 'Additional borrowing',
      },
    },
    contactInfo: {
      yourCurrentAddress: 'Your current address',
      previousAddress: 'Where did you live before?',
      accordionTitle: 'Why do we ask this?',
      accordionDesc: 'Lenders need 3 years of address history',
      removeAddress: 'Remove address',
    },
    onBoarding: {
      getStarted: 'Get started',
      termsAndConditions: 'Terms and conditions',
      confirmYourDetails: 'Confirm your details',
      idd: {
        title: 'Terms and conditions',
        button: 'Accept and continue',
        desc: 'Before we move on, it is important that you review the terms in this document. The following information is to help you decide if our services are right for you. You can find a full and detailed version in the portal',
        sign: 'By pressing continue, you are agreeing to: above disclosure, {terms}, {privacy}, a copy of which will be available in the portal.',
        agreeingToDisclosure: 'By clicking accept and continue, you are agreeing to: the above disclosure, ',
        orgPrivacyPolicy: `{orgName}'s privacy policy`,
        acrePrivacyPolicy: `Acre's privacy policy.`,
        and: ' and ',
        copies: ' Copies of which will be available to view in your client portal',
      },
      detailsForm: {
        title: 'Confirm your details',
        desc: 'Please fill out the below information and then you can get started exploring your client portal.',
      },
      buttons: {
        continue: 'Continue',
        selfie: 'Take a selfie',
        confirmSelfie: 'My selfie is clear',
        dashboard: 'Explore my dashboard',
        upload: 'Tap to upload',
        retake: 'Retake',
        DRIVERS_LICENSE_FRONT: 'My license is readable',
        DRIVERS_LICENSE_BACK: 'My license is readable',
        PASSPORT: 'My passport is readable',
        NATIONAL_IDENTITY_CARD: 'My ID card is readable',
      },
    },
    caseDetailsCard: {
      yourHousePurchase: 'Your house purchase',
      yourRemortgage: 'Your remortgage',
      insuranceApplication: 'Your insurance application',
      yourLoan: 'Your Loan',
      tesUsAboutYourself: 'Tell us about yourself',
      tesUsAboutYourselfBody:
        'Help your advisor find the best mortgage for you by filling in your details and uploading the required documents.',
      prepApplication: 'Preparing your application',
      prepApplicationBody:
        'Your advisor is currently applying for your chosen mortgage product on your behalf. They will be get in touch if they need anything from you in the meantime.',
      applicationSubmmited: 'Application submitted',
      applicationSubmmitedBody:
        'Your application is being reviewed by your chosen lender. They will get an independent valuation of the property to make sure everything is in order before they respond',
      awaitingOffer: 'Awaiting offer',
      awaitingOfferBody:
        'Your application is now going through one last review before your chosen lender makes a decision. Your advisor will be in touch if they need any additional details to help the process along',
      applicationSucessful: 'Application successful',
      applicationSucessfulBody:
        'Congratulations, your application was successful and the lender has issued a mortgage offer. You can find this in [Documents]',
      applicationComplete: 'Application complete',
      applicationCompleteBody: 'Your application has completed.',
    },
    DateError: {
      underAge: 'Age must be at least 18 years old',
      overAge: 'Age must be below 125 years old',
      future: 'Date cannot be in the future',
      past: 'Date cannot be in the past',
      mustBeInFuture: 'Date must be in the future',
      input: 'Enter a date in the format DD/MM/YYYY',
      day: 'Day must be between 1 and 31',
      month: 'Month must be between 1 and 12',
      generic: 'Enter a date in the format DD/MM/YYYY',
      before: 'Start date must be before the end date',
      after: 'End date must be after the start date',
      beforeLabel: '{first} must be before {second}',
      afterLabel: '{first} must be after {second}',
      beforeTermEnd: 'Initial period must end before mortgage term',
      endAfterStartDate: 'End date must be after start date',
      startBeforeEndDate: 'End date cannot be before start date',
      renewalCommissionStartDateMustBeGreaterThanInitialCommissionEndDate:
        '{label} must be greater than {initialCommissionEndDate}.',
      dateRegisteredAfterDateSatisfied: 'Default date registered cannot be after default date satisfied',
      startDateAfterDateRegistered: 'Start date cannot be after default date registered',
      endDateBeforeDateRegistered: 'End date cannot be before default date registered',
      startDateBeforeBusinessEstablished: 'Start date cannot be before business established',
      dateArrears: 'Date arrears cleared cannot be before date of last arrears',
      dateArrearsStartDate: 'Date of last arrears cannot be before start date',
      validDateRequired: 'A valid MM/YYYY date is required',
    },
    errors: {
      accountNumber: 'Enter an account number in the correct format, like XXXXXXXX',
      email: 'Enter email address in the correct format, like name@email.com',
      generic: 'Something went wrong',
      greaterThan: '{first} must be greater than {second}',
      inputMustBeLetter: 'Input must be a letter',
      inputMustBeNumber: 'Input must be a number',
      inputMustBePositiveNumber: 'Input must be a positive number',
      invalidFormat: 'Invalid format',
      lessThan: '{first} must be less than {second}',
      phone: 'Enter a phone number between 10 and 14 digits long',
      postcode: 'Enter a valid postcode between 5 and 7 characters long',
      positive: 'Enter an {label} above 0',
      required: 'This field is required',
      sortCode: 'Enter a sort code in the format XXXXXX or XX XX XX',
      employmentDateInFuture: 'Employment start date cannot be in the future',
      employmentEndDateInFuture: 'Employment end date cannot be in the future',
      cannotBeEarlierThanStartDate: 'End date cannot be earlier than start date',
      percentage: 'Percentage must be between 0 and 100',
      invalidNi: 'Enter your NI number in the correct format, like QQ123456A',
      somethingWentWrong: `Something's gone wrong`,
      contactSupport: `Apologies, this isn't working as it should. If this happens again, contact`,
      reloadPage: 'Reload page',
      oops: 'Oops! Something went wrong. If problem persists, please email your broker',
      propertyPriceRange: 'Input should be between £1 and £100,000,000',
      propertyRoomRange: 'Number of bedrooms should be between 1 - 50',
      borrowingRange: 'Amount must be between £1 and £100,000,000',
      termGreaterThan100Years: 'Mortgage term cannot be greater than 100 years',
      depositRange: 'Deposit amount must be between £1 and £100,000,000',
      firstName: 'Enter a first name',
      surname: 'Enter a surname',
      firstNameLetters: 'Enter a first name using just letters',
      surnameLetters: 'Enter a surname using just letters',
      middleNameLetters: 'Enter a middle name using just letters',
      homepage: 'Homepage',
      greaterThanZero: 'Must be greater than zero',
      maxFiveDependants: 'If you have more than 5 dependants, please get in touch with your broker',
      maxThree: 'There is a maximum of three',
      notEngland: 'We cannot provide a quote for a property outside England',
      percentage99: 'Entered percentage must be 99% or less',
      lessThanLoanAmount: 'Entered amount must be less than total loan amount',
    },
    generic: {
      add: 'Add',
      and: 'and',
      overview: 'Overview',
      name: 'Name',
      or: 'or',
      mobilePhone: 'Mobile phone number',
      emailAddress: 'Email address',
      currentAddress: 'Current address',
      incompleteAddress: 'Incomplete address',
      dateOfBirth: 'Date of birth',
      getStarted: 'Get started',
      findOutMore: 'Find out more',
      yes: 'Yes',
      no: 'No',
      YES: 'Yes',
      NO: 'No',
      not: 'not',
      typeHere: 'Type here',
      typeToSearch: 'Type to search',
      optional: 'Optional',
      unknown: 'Unknown',
      edit: 'Edit',
      delete: 'Delete',
      continue: 'Continue',
      save: 'Save',
      day: 'Day',
      days: 'Days',
      month: 'Month',
      months: 'Months',
      year: 'Year',
      years: 'Years',
      noInformation: 'Information missing',
      summary: 'Summary',
      total: 'Total',
      nothingToShow: 'Nothing to show',
      back: 'Back',
      home: 'Home',
      exit: 'Exit',
      tryAgain: 'Something went wrong, try again',
      continueFillingInDetails: 'Continue filling in details',
      continueToNextSection: 'Next section',
      finish: 'Finish',
      doItLater: 'Do it later',
      acceptAndContinue: 'Accept and continue',
      allFinished: 'All finished',
      checkInformation: 'Check your details are all filled in and correct',
      bedroom: 'Bedroom',
      unsure: 'Unsure',
      you: 'You',
      mins: 'mins',
      changeAddress: 'Change address',
      collapse: 'Collapse',
      submit: 'Submit',
      remove: 'Remove',
      open: 'Open',
      close: 'Close',
    },
    nav: {
      address: 'Address',
      home: 'Home',
      onboarding: 'Onboarding',
      documents: 'Documents',
      profile: 'Profile',
      personal: 'Personal details',
      employments: 'Employment',
      contacts: 'Contact details',
      protection: 'Health and Protection ',
      existing_property: 'Existing property',
      requirements: 'Requirements',
      other_properties: 'Other properties',
      property_purchase: 'Property To Purchase',
      borrowing: 'Requirements',
      goBack: 'Go back',
      backToDashboard: 'Back to dashboard',
      saveAndContinue: 'Save and continue',
    },
    reviewYourDetails: {
      reviewTitle: 'Review your details',
      reviewSubTitle: `Check your details are all filled in and correct`,
      personalDetails: 'Your personal details',
      button: 'My details are correct',
    },
    scheduler: {
      introduction: {
        duration: '{duration} mins',
        selectTime: 'Select a date and time slot that works for you.',
        selectDateCta: 'Select a date',
      },
      timePicker: {
        title: 'Pick a time',
        confirm: 'Confirm',
        meetingTitle: 'Meeting with {client} and {broker}',
      },
      confirmation: {
        title: 'Meeting confirmed',
        text: 'A calendar invitation has been sent to your email address',
      },
      generic: {
        back: 'Back',
      },
    },
    protection: {
      protection: 'Health and Protection ',
      shouldReviewCoveradge: 'Would you like us to review your existing Health and Protection coverage?',
    },
    property: {
      addProperty: '+ Add property',
      otherProperties: 'Do you own any other properties?',
      noOtherProperties: `I don't own any other properties`,
      existingProperty: 'Existing property',
      checkPropertyAddressEntered:
        'Check your details are all filled in and correct. Make sure you enter your properties address before you fill in anymore information.',
      address: 'Address',
      valuation: 'Valuation',
      howMuchPropertyWorth: 'How much is the property worth?',
      purchasePrice: 'Purchase price',
      howMuchPropertyPurchased: 'How much was the property purchased for?',
      kindOfProperty: 'What kind of property is it?',
      propertyType: 'Property type',
      numberOfBedrooms: 'Number of bedrooms',
      hasMortgage: 'Does {address} already have a mortgage?',
      hasPropertyMortgage: 'Does property already have a mortgage?',
      bedroom: 'Bedroom',
      isPropertyLetOut: 'Is the property let out?',
      monthlyRentIncome: `What's the monthly rental income?`,
      propertyTypes: {
        bungalow: 'Bungalow',
        flat: 'Flat',
        house: 'House',
        house_boat: 'House boat',
        maisonette: 'Maisonette',
      },
    },
    mortgage: {
      hasExistingMortgage: 'Does this property have a mortgage on it?',
      existingMortgage: 'Existing mortgage',
      lender: 'Lender',
      remainingBalance: 'Remaining balance',
      monthlyPayment: 'Monthly payment',
      remainingTerm: 'Remaining term',
      currentRate: 'Current rate',
      rateEnd: 'Rate end',
      existingMortgageFor: 'Existing mortgage for {address}',
      whoIsLender: 'Which lender is your mortgage currently with?',
      whatIsRemainingBalance: 'How much do you have left to pay off on your mortgage?',
      whatIsMonthlyPayment: 'How much are your monthly payments?',
      whatIsTheRemainingTerm: `How long is left on your mortgage?`,
      whatIsCurrentRate: `What's the current rate?`,
      whatIsRateType: `What's the current rate type?`,
      whenRateEnds: 'When does this rate end? You can round to the nearest month',
      rateTypeOptions: {
        class_fixed: 'Fixed',
        class_discount: 'Discount',
        class_variable: 'Variable',
        class_capped: 'Capped',
        class_libor: 'LIBOR',
        class_tracker: 'Tracker',
        class_stepped: 'Stepped',
      },
    },
    propertyTypes: {
      HOUSE: 'House',
      FLAT: 'Flat',
      BUNGALOW: 'Bungalow',
      HOUSE_BOAT: 'House boat',
      MAISONETTE: 'Maisonette',
    },
    authentication: {
      userDisambiguation: {
        title: "We've found more than one client with that email address",
        description: 'Which of these accounts would you like to log in with?',
        company: 'Advisor Company: {name}',
        advisorName: 'Advisor Name: {name} {surname}',
      },
      deniedAccess: {
        title: `Oh no, we're sorry!`,
        desc: `You are sharing a case with someone else and currently you do not have permission to view their information. If you think this is not correct, please contact your broker and they will assist you. `,
      },
      caseDisambiguation: {
        title: "We've found a few cases for this account",
        description: 'Which of these cases would you like to log in with?',
        clients: 'Clients: {clients}',
        created: 'Created: {dateTime}',
        noCasesTitle: `Oh no, we're sorry!`,
        noCasesDesc: `Unfortunately we couldn't find any cases on your account. Please get in touch with your broker and they
        will assist you.`,
        mortgageTypes: {
          REASON_FTB: 'Property purchase',
          REASON_HOUSE_MOVE: 'House move',
          REASON_REMORTGAGE: 'Remortgage',
          REASON_BTL: 'Buy-to-let',
          REASON_BTL_REMORTGAGE: 'Buy-to-let remortgage',
          REASON_EQUITY_RELEASE: 'Equity release',
          REASON_FURTHER_ADVANCE: 'Further advance',
          REASON_BUSINESS_PROTECTION: 'Business protection',
          REASON_PROTECTION: 'Health and Protection',
          REASON_COMMERCIAL: 'Commercial mortgage',
          REASON_BRIDGING: 'Bridging finance',
          REASON_GENERAL_INSURANCE: 'Home insurance',
          fallback: 'No case type selected',
        },
      },
      clientError: {
        title: `Oh no, we're sorry!`,
        desc: `Unfortunately we encountered an unexpected error. Please get in touch with your broker and they
        will assist you.`,
      },
    },
    additionalLoanPurposes: {
      debtConsolidation: 'Debt consolidation',
      homeImprovements: 'Home improvements',
      buyOtherProperty: 'Buy other property',
      divorceSettlement: 'Divorce settlement',
      capitalRaising: 'Capital raising',
      car: 'Car',
      holiday: 'Holiday',
      schoolFees: 'School fees',
      businessPurposes: 'Business',
      staircasing: 'Staircasing',
      other: 'Other',
    },
    additionalBorrowingForm: {
      title: 'Additional borrowing',
      desc: "Please note this is for borrowing on top of your remaining mortgage balance, we'll ask about that later.",
      doYouRequireBorrowing: 'Do you need additional borrowing?',
      addReason: '+ Add reason',
      addAnotherReason: '+ Add another reason',
      reason: 'Reason',
      howMuch: 'How much?',
    },
    messages: {
      placeholder: 'Type your message',
      startOfMessages: 'This is the start of your messages with {brokerName}',
      unreadMessages: '{length} unread messages',
      meetingConfirmation: 'Meeting confirmation: {title}',
      unread: '{unreadCount} unread',
      noPreviousMessages: 'No previous messages',
      unreadMsg: '{unreadCount} unread message',
      unreadMsgs: '{unreadCount} unread messages',
      send: 'Send',
      mortgageTypes: {
        REASON_FTB: 'Property purchase case',
        REASON_HOUSE_MOVE: 'House move case',
        REASON_REMORTGAGE: 'Remortgage case',
        REASON_BTL: 'Buy-to-let case',
        REASON_BTL_REMORTGAGE: 'Buy-to-let remortgage case',
        REASON_EQUITY_RELEASE: 'Equity release case',
        REASON_FURTHER_ADVANCE: 'Further advance case',
        REASON_BUSINESS_PROTECTION: 'Business protection case',
        REASON_PROTECTION: 'Protection case',
        REASON_COMMERCIAL: 'Commercial mortgage case',
        REASON_BRIDGING: 'Bridging finance case',
        REASON_GENERAL_INSURANCE: 'Home insurance case',
        unknown: 'Unknown case type',
      },
    },
    wills: {
      setupTitle: `It's fast and easy to set up your will and LPAs`,
      setupDesc: `We've chosen HoneyPro as our partner to make it as simple as possible to sort out these essential legal
              documents. This simple online, step by step process is fully supported with advice and further options to
              meet your needs:`,
      setupPoints: `<li>One of the UK's most trusted legal brands</li><li>Free client report recommending how to fully protect your family with reasons why</li><li>Free lifetime updates and secure document storage</li><li>Protect your family and assets from as little as £99</li>`,
      title: 'Is your family, home and mortgage fully protected?',
      points: `<li><strong>If you die without a will,</strong> intestacy laws apply. Your property and estate may not all go to your partner – especially if you're not married. Some wealth may pass direct to your children. Former partners and other family members could make a claim.</li>{br}<li><strong>Not having a will can mean children are taken into care.</strong> Protect them by stating in your will who you want to look after your family.</li>{br}<li><strong>Without a will it takes much longer for your wealth and property to be passed on to others,</strong> adding stress at an already difficult time.</li>{br}<li><strong>Without LPAs in place, if you lost capacity due to illness, injury or Dementia, your finances would be frozen.</strong> Not even your partner can step in and make decisions for you, and they'll be blocked from renegotiating a new fix rate deal in the future, which may mean going onto a standard variable rate.</li>`,
      didYouKnow: 'Did you know?',
      doYouHaveAWill: 'Do you have a will?',
      willReviewed: 'Have you had your will reviewed in the last five years?',
      accordionTitle: `What happens if you don't have a will?`,
      accordionDesc: `<li>Should the worst happen, you will die intestate, which means the government decides how your estate shall be distributed</li>{br}<li>If you are unmarried, your partner may not get any of your assets</li>{br}<li>If you're married with children, your spouse may not automatically inherit everything you own</li>{br}<li>It takes longer to distribute your assets and close your estate. You can save your beneficiaries time and expense</li>{br}<li>Should the worst happen the only way without a court order of appointing guardians for children under 18 is through a will. For example, grandparents do not have automatic parental responsibility</li>`,
      modal: {
        title: 'Is your family, home and mortgage fully protected?',
        content: `<p>Did you know that a will is the only surefire way of ensuring your assets pass to the people you want them to in the way you want them to?</p>{br}<p>HoneyPro is our trusted wills partner. This simple online process is fully supported with advice and further options to meet your needs:</p>{br}<ul><li>One of the UK's most trusted legal brands</li><li>Free client report recommending how to fully protect your family with reasons why</li><li>Protect your family and assets from as little as £99</li></ul>`,
      },
    },
    surveying: {
      standardConstruction: 'Less than 100 years old or standard construction',
      unusualConstruction: 'Over 100 years old or unusual construction',
      title: `Choosing the survey that's right for you`,
      desc: `The survey you need will largely depend on the age and condition of the property you're looking to purchase.{br}{br}Choose the description that best describes your property below and click the link to find out about the survey that will tell you what you need to know.`,
      didYouKnow: 'Getting the right survey is important',
      actionDesc: `An RICS approved survey from Acre Surveying will help you make an informed decision when it comes to purchasing a new property.{br}{br}With surveyors based throughout the UK, your survey will be conducted by a fully qualified professional who is:`,
      actionPoints:
        '<li>Committed to providing exceptional service</li><li>Committed to providing high quality reports</li><li>Including the level of information you need, at a competitive price</li>',
      standardDesc:
        'If the property in question is less than 100 years old, and is of standard construction, then one or more of the inspections below will likely be required to give you the level of information you need.',
      homebuyerReport: 'RICS Home Survey Level 2',
      homebuyerReportDesc: `An RICS Home Survey Level 2 (formerly known as a Homebuyer's Report) is intended for conventional, residential properties that appear to be in a reasonable condition and have not been subject to significant alterations.{br}{br}This survey provides an account of the property's condition and highlights significant problems with straightforward 'traffic light' ratings. It focuses on urgent or significant defects that may be costly to repair, or which may affect its future marketability.`,
      unusualDesc:
        'If the property in question is more than 100 years old, and/or it is of unusual construction, then one or more of the inspections below will likely be required to give you the level of information you need.',
      buildingSurvey: 'RICS Home Survey Level 3',
      buildingSurveyDesc: `An RICS Level 3 Home Survey (formerly known as a Building Survey) is the most in-depth and comprehensive report available. It is recommended for properties that are of unusual construction, dilapidated, have been altered, or where major renovation or conversion is planned.{br}{br}It includes a thorough inspection and report which highlights any potential issues arising from visible and hidden flaws, provides an outline of the repair options available, and draws attention to serious or potentially dangerous conditions.`,
      specialistReport: 'Specialist Report',
      specialistReportDesc: `Sometimes an initial valuation or survey will identify the need for further specialist reports. These are usually regarding specific issues such as damp, drainage or structural problems. On occasion, expert assessments on even more specific issues such as wall ties, risks from trees, asbestos, or environmental hazards are also recommended.`,
      thankYou: 'Thank you!',
      thankYouDesc: `Congratulations! You've successfully chosen Acre Surveying as your property survey partner.{br}{br}Someone will get in touch shortly regarding your survey.`,
      modal: {
        title: 'Thank you!',
        desc: `You're all done, thank you! Someone will get in touch shortly regarding your survey.`,
      },
      referMe: 'Click here to request a quotation',
      badFields:
        'We need more information before we can accept a referral. Please return to the fact-find and complete {missing} before continuing.',
      factFind: 'Complete fact-find to get a quotation',
    },
    payments: {
      complete: {
        mandate: 'Thanks for setting up payments',
        mandateBody:
          'Thanks for setting up your mandate. Your broker can now collect payments for case fees when they become due.',
        header: 'Thank you for your payment',
        body: 'Thank you for sending payment to your broker using GoCardless and Acre. Payments typically clear within a few hours.',
        questions: 'If you have any questions, please do not hesitate to send your broker a message.',
        return: 'Return to your dashboard',
      },
      failed: {
        header: 'Payment failed',
        body: 'Unfortunately, GoCardless were unable to process your payment at the current time. This may be because you chose to exit the payment process or because of a problem with the details you entered. If you have any questions about this, please contact your broker.',
      },
    },
    survey: {
      button: {
        next: 'Next',
        back: 'Back',
        submit: 'Submit',
        return: 'Go to my client portal',
      },
      complete: {
        header: 'Thanks for your feedback!',
        text: `We appreciate you taking the time to share your thoughts with us. Your feedback helps us improve our service and continue providing the best possible experience for our clients. If you have any additional questions or need further support, please don't hesitate to reach out.{br}{br}Thank you again for choosing us for your mortgage journey!`,
      },
    },
    surveys: {
      tellUsHowWeDid: 'Tell us how we did',
      giveFeedback: 'Give us your feedback on your experience getting this mortgage',
    },
  },
}

export default messagesClientPortal
